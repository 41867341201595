import apiClient from "@/services/axios";
import moment from "moment";
import JsPDF from "jspdf";
import { Modal } from "ant-design-vue";

let bulletinfOnTwoPages = false;
const totalPtsTreshold = 18.5;
let studentCount = 1;

let bulletinColors = {
  color1: [70, 187, 239],
  color2: [64, 187, 239],
  color3: [16, 169, 229],
  color4: [67, 62, 63],
  color5: [52, 172, 215],
  color6: [224, 237, 243],
  color7: [64, 187, 239],
  color8: [225, 225, 226],
  color9: [224, 237, 243],
};

const fuseSubSubjects = (student) => ({
  ...student,
  modules: student.modules.map((m) => ({
    ...m,
    subjects: m.subjects.map((s) => {
      if (s.subSubjectMarks) delete s.subSubjectMarks;
      return s;
    }),
  })),
});

const waitLoadingImage = async function (image) {
  return new Promise((res, rej) => {
    image.onload = (t) => {
      res(t);
    };
    image.onerror = (e) => {
      rej(e);
    };
  });
};

const drawModuleHeader = function (
  doc,
  ctx,
  startY,
  title,
  hideSubjectMax = false
) {
  // module title

  let x = 65;
  let y = 63 + startY;
  let width = 125;
  doc.setFillColor(...bulletinColors.color2);
  roundRect(ctx, x, y, width, 8, {}, true, true);
  doc.setFillColor(0xff, 0xff, 0xff);
  doc.setDrawColor(0xff, 0xff, 0xff);
  doc.rect(x, y + 20, width + 10, 10, "FD");

  // module header shapes
  x = 155;
  y = 72.6 + startY;
  width = 47;
  doc.setFillColor(...bulletinColors.color2);
  roundRect(ctx, x, y, width, 7.5, {}, true, true);
  // doc.setFillColor(0xff, 0xff, 0xff);
  // doc.setDrawColor(0xff, 0xff, 0xff);
  // doc.rect(x, y + 9.9, width + 10, 10, "FD");

  x = 139;
  y = 72.6 + startY;
  width = 16;
  doc.setFillColor(...bulletinColors.color6);
  //العدد / 20
  roundRect(ctx, x, y, width, 7.4, {}, true, true);
  // doc.setFillColor(0xff, 0xff, 0xff);
  // doc.setDrawColor(0xff, 0xff, 0xff);
  // doc.rect(x, y + 9.9, width + 10, 10, "FD");

  doc.setLineWidth(0.4);
  doc.setFillColor(...bulletinColors.color6);
  doc.setDrawColor(...bulletinColors.color2);
  //معدل المجال;
  doc.rect(124, 72.3 + startY, 15, 8, "FD");
  //توصيّات المدّرس
  doc.rect(86, 72.3 + startY, 38, 8, "FD");
  //عدد بالقسم

  doc.rect(65, 72.3 + startY, 10, 8, "FD");
  doc.rect(75, 72.3 + startY, 10, 8, "FD");

  doc.line(155, 80.3 + startY, 202.5, 80.3 + startY);

  // header module texts
  if (/[a-zA-Z]/.test(title)) doc.setFont("Amiri", "Bold");
  else doc.setFont("Noto Sans Arabic", "Bold");
  doc.setFontSize(13);
  let text = title;
  doc.setTextColor(0xff, 0xff, 0xff);
  width = doc.getTextWidth(text);
  doc.text(text, 138 - width / 2 - 15, 69 + startY);

  doc.setFont("Amiri", "Bold");
  text = "المادة";
  width = doc.getTextWidth(text);
  doc.text(text, 179, 78 + startY);

  text = "العدد / 20";
  if (hideSubjectMax) text = "العدد";
  doc.setFontSize(9);
  doc.setTextColor(...bulletinColors.color2);
  width = doc.getTextWidth(text);
  doc.text(text, hideSubjectMax ? 145 : 141, 78 + startY);

  text = "معدل\nالمجال";
  doc.setFontSize(9);
  doc.setTextColor(...bulletinColors.color2);
  width = doc.getTextWidth(text);
  doc.text(text, 132, 75.2 + startY, "center");

  text = "توصيّات المدّرس";
  doc.setFontSize(11);
  doc.setTextColor(...bulletinColors.color2);
  width = doc.getTextWidth(text);
  doc.text(text, 94, 77.5 + startY);

  text = "أعلى\nعدد بالقسم";
  doc.setFontSize(6);
  doc.setTextColor(...bulletinColors.color2);
  width = doc.getTextWidth(text);
  /**
     * style == 0 ? 80 : 65, 72.3 + startY, 10, 8, "FD");
    doc.rect(style == 0 ? 90 : 75,
     */
  doc.text(text, 80, 75 + startY, { align: "center" });

  text = "أدنى\nعدد بالقسم";
  doc.setFontSize(6);
  doc.setTextColor(...bulletinColors.color2);
  width = doc.getTextWidth(text);
  doc.text(text, 70, 75 + startY, { align: "center" });
};

const drawModuleHeaderMondial = function (doc, ctx, startY, title) {
  // module title
  let x = 65;
  let y = 63 + startY;
  let width = 125;
  doc.setFillColor(0xff, 0xff, 0xff);
  doc.setDrawColor(0xff, 0xff, 0xff);
  //doc.rect(x, y + 20, width + 10, 10, "FD");

  // module header shapes
  x = 95;
  y = 72.6 + startY;
  width = 109;
  doc.setFillColor(...bulletinColors.color2);
  // المادة rect
  roundRect(ctx, x, y, width, 7.5, {}, true, true);

  x = 139 - 60;
  y = 72.6 + startY;
  width = 16;
  doc.setFillColor(...bulletinColors.color6);
  roundRect(ctx, x, y, width, 7.4, {}, true, true);

  doc.setLineWidth(0.4);
  doc.setFillColor(...bulletinColors.color6);
  doc.setDrawColor(...bulletinColors.color2);
  doc.rect(124 - 60, 72.3 + startY, 15, 8, "FD");
  doc.rect(86 - 60, 72.3 + startY, 38, 8, "FD");
  doc.rect(65 - 60, 72.3 + startY, 10, 8, "FD");
  doc.rect(75 - 60, 72.3 + startY, 10, 8, "FD");

  // doc.line(155 - 60, 80.3 + startY, 202.5, 80.3 + startY);

  // Add new rectangle for "Module"
  x = 202.5 - 60;
  y = 72.3 + startY;
  width = 25;
  let height = 8;
  doc.setFillColor(...bulletinColors.color2);
  doc.setDrawColor(...bulletinColors.color2);
  // doc.rect(x, y, width, height, "FD");

  // Add text "Module" inside the new rectangle
  doc.setFont("Amiri", "Bold");
  doc.setFontSize(13);
  doc.setTextColor(0xff, 0xff, 0xff);
  let text = "المجال";
  let textWidth = doc.getTextWidth(text);
  doc.text(text, 171, y + height - 2);

  doc.setFont("Amiri", "Bold");
  text = "المادة";
  width = doc.getTextWidth(text);
  doc.text(text, 179 - 60, 78 + startY);

  text = "العدد / 20";
  doc.setFontSize(9);
  doc.setTextColor(...bulletinColors.color2);
  width = doc.getTextWidth(text);
  doc.text(text, 141 - 60, 78 + startY);

  text = "معدل\nالمجال";
  doc.setFontSize(9);
  doc.setTextColor(...bulletinColors.color2);
  width = doc.getTextWidth(text);
  doc.text(text, 132 - 60, 75.2 + startY, "center");

  text = "توصيّات المدّرس";
  doc.setFontSize(11);
  doc.setTextColor(...bulletinColors.color2);
  width = doc.getTextWidth(text);
  doc.text(text, 94 - 60, 77.5 + startY);

  text = "أعلى\nعدد بالقسم";
  doc.setFontSize(6);
  doc.setTextColor(...bulletinColors.color2);
  width = doc.getTextWidth(text);
  doc.text(text, 80 - 60, 75 + startY, { align: "center" });

  text = "أدنى\nعدد بالقسم";
  doc.setFontSize(6);
  doc.setTextColor(...bulletinColors.color2);
  width = doc.getTextWidth(text);
  doc.text(text, 70 - 60, 75 + startY, { align: "center" });
};

const roundRect = function (ctx, x, y, width, height, radius, fill, stroke) {
  const cornerRadius = {
    upperLeft: 0,
    upperRight: 0,
    lowerLeft: 0,
    lowerRight: 0,
  };
  if (typeof stroke == "undefined") {
    stroke = true;
  }
  if (typeof radius === "object") {
    for (const side in radius) {
      cornerRadius[side] = radius[side];
    }
  }

  ctx.lineWidth = 1;
  ctx.strokeStyle = `rgb(${bulletinColors.color1[0]}, ${bulletinColors.color1[1]}, ${bulletinColors.color1[2]})`;

  ctx.beginPath();
  ctx.moveTo(x + cornerRadius.upperLeft, y);
  ctx.lineTo(x + width - cornerRadius.upperRight, y);
  ctx.quadraticCurveTo(x + width, y, x + width, y + cornerRadius.upperRight);
  ctx.lineTo(x + width, y + height - cornerRadius.lowerRight);
  ctx.quadraticCurveTo(
    x + width,
    y + height,
    x + width - cornerRadius.lowerRight,
    y + height
  );
  ctx.lineTo(x + cornerRadius.lowerLeft, y + height);
  ctx.quadraticCurveTo(x, y + height, x, y + height - cornerRadius.lowerLeft);
  ctx.lineTo(x, y + cornerRadius.upperLeft);
  ctx.quadraticCurveTo(x, y, x + cornerRadius.upperLeft, y);
  ctx.closePath();
  if (stroke) {
    ctx.stroke();
  }
  if (fill) {
    ctx.fill();
  }
};

const componentToHex = function (c) {
  var hex = c.toString(16);
  return hex.length == 1 ? "0" + hex : hex;
};

const rgbToHex = function (r, g, b) {
  return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
};

const getCertificationNameByMoyenne = function (moyenne, certifs) {
  for (const cert of certifs)
    if (
      Number(moyenne) >= Number(cert.min) &&
      Number(moyenne) <= Number(cert.max)
    ) {
      return cert.name;
    }

  return "";
};

const drawTrimesterMoyenneAndCards = function (
  doc,
  ctx,
  startY,
  moy,
  max,
  min,
  pedaMoyenne,
  personalizedMoyenne,
  affichageBulletin,
  stampImage,
  config,
  useTemplateSchool = null
) {
  const currentPageInfo = doc.getCurrentPageInfo();
  if (bulletinfOnTwoPages) doc.setPage(currentPageInfo.pageNumber - 1);
  const { selectedLevel, schoolDetails, bulletinDate, certifs, bulletinType } =
    config;
  const value = 5;
  let yPosition = startY + 68.2;

  let text;
  let width;
  //bulletin pilote and specifique have different fieldNames in paramétrage affichage
  let fieldVerySpecific = bulletinType == "MS" ? "verySpecific" : "specific";
  let fieldverySpecificName =
    bulletinType == "MS" ? "verySpecificName" : "specificName";
  if (affichageBulletin[fieldVerySpecific]) {
    doc.setFillColor(...bulletinColors.color5);
    doc.setDrawColor(...bulletinColors.color5);
    roundRect(
      ctx,
      45 - value * 2,
      yPosition,
      33.5 - value,
      8.2,
      {},
      true,
      false
    );

    doc.setFont("Amiri", "Bold");
    doc.setFontSize(11);
    text = affichageBulletin[fieldverySpecificName];
    doc.setTextColor(0xff, 0xff, 0xff);
    width = doc.getTextWidth(text);
    doc.text(text, 65.5 - value * 3 - width / 2, yPosition + 5.3);

    doc.setLineWidth(0.2);
    doc.setDrawColor(...bulletinColors.color2);
    doc.setFillColor(...bulletinColors.color6);

    roundRect(ctx, 6, yPosition, 38 - value * 2, 7.8, {}, true, true);
    roundRect(ctx, 6, yPosition, 19 - value, 7.8, {}, true, true);

    doc.setFont("Amiri", "Bold");
    doc.setFontSize(7);
    doc.setTextColor(...bulletinColors.color2);
    doc.text("أعلى\nمعدل بالقسم", 35 - (value + value / 2), yPosition + 3.3, {
      align: "center",
    });
    doc.text("أدنى\nمعدل بالقسم", 16.2 - value / 2, yPosition + 3.3, {
      align: "center",
    });

    // moyenne rect
    doc.setFillColor(...bulletinColors.color6);
    doc.setDrawColor(...bulletinColors.color2);
    doc.setLineWidth(0.15);
    doc.rect(45 - value * 2, yPosition + 9.3, 33.5 - value, 16.4, "FD");

    doc.setFillColor(...bulletinColors.color8);
    doc.rect(6, yPosition + 9.3, 19 - value, 16.4, "FD");
    doc.rect(25 - value, yPosition + 9.3, 19 - value, 16.4, "FD");

    // moyenne text
    doc.setTextColor(...bulletinColors.color4);
    if (useTemplateSchool == "CP") doc.setTextColor(255, 0, 0);
    doc.setFontSize(16);
    text = String(moy);
    width = doc.getTextWidth(text);
    doc.text(text, 63 - width / 2 - (value * 2 + value / 2), yPosition + 18.3);
    doc.setTextColor(...bulletinColors.color4);
    doc.setFontSize(13);
    text = String(max);
    width = doc.getTextWidth(text);
    doc.text(text, 34.75 - width / 2 - (value + value / 2), yPosition + 18.3);

    text = String(min);
    width = doc.getTextWidth(text);
    doc.text(text, 15.25 - width / 2 - value / 2, yPosition + 18.3);

    yPosition += 27; //99.2
  }

  if (affichageBulletin.pedagogic) {
    doc.setFillColor(...bulletinColors.color5);
    doc.setDrawColor(...bulletinColors.color2);
    roundRect(ctx, 6, yPosition, 73 - value * 3, 8.2, {}, true, false);

    doc.setFillColor(...bulletinColors.color6);
    doc.setDrawColor(...bulletinColors.color2);
    doc.setLineWidth(0.05);

    doc.rect(6, yPosition + 9, 73 - value * 3, 8.2, "FD");

    doc.setFontSize(10);
    text = affichageBulletin.pedagogicName;
    width = doc.getTextWidth(text);
    doc.setTextColor(0xff, 0xff, 0xff);
    doc.text(text, 45 - width / 2 - value * 2, yPosition + 5.3);

    doc.setFontSize(11);
    text = pedaMoyenne ? String(pedaMoyenne) : "--";
    width = doc.getTextWidth(text);
    doc.setTextColor(...bulletinColors.color4);
    doc.text(text, 45 - width / 2 - value * 2, yPosition + 14.3);

    yPosition += 20; //110
  }

  if (affichageBulletin.personalized) {
    doc.setFillColor(...bulletinColors.color5);
    doc.setDrawColor(...bulletinColors.color2);
    roundRect(ctx, 6, yPosition, 73 - value * 3, 8.2, {}, true, false);

    doc.setFillColor(...bulletinColors.color6);
    doc.setDrawColor(...bulletinColors.color2);
    doc.setLineWidth(0.05);
    doc.rect(6, yPosition + 9, 73 - value * 3, 8.2, "FD");

    doc.setFontSize(10);
    text = affichageBulletin.personalizedName;
    width = doc.getTextWidth(text);
    doc.setTextColor(0xff, 0xff, 0xff);
    doc.text(text, 45 - width / 2 - value * 2, yPosition + 5.3);

    doc.setFontSize(11);
    width = doc.getTextWidth(personalizedMoyenne);
    doc.setTextColor(...bulletinColors.color4);
    doc.text(personalizedMoyenne, 45 - width / 2 - value * 2, yPosition + 14.5);

    yPosition += 20; // 121
  }
  //الشّهادة
  if (affichageBulletin.certif) {
    doc.setFillColor(...bulletinColors.color6);
    doc.setDrawColor(...bulletinColors.color2);

    yPosition -= 36;

    doc.rect(6, yPosition + 42, 72.5 - value * 3, 20);
    doc.rect(40.75 - value * 3, yPosition + 38, 36.75, 8, "FD");

    doc.setFontSize(13);
    doc.setTextColor(...bulletinColors.color2);

    text = "الشّهادة";
    doc.text(text, 55 - value * 3, yPosition + 43);
    doc.setTextColor(67, 62, 63);
    if (useTemplateSchool == "CP") doc.setTextColor(255, 0, 0);

    doc.text(
      getCertificationNameByMoyenne(moy, certifs),

      40 - value * 3,
      yPosition + 55
    );
    yPosition += 65;
  }
  //مدير)ة( المدرسة"
  doc.setLineWidth(0.15);

  doc.setFillColor(...bulletinColors.color6);
  doc.setDrawColor(...bulletinColors.color2);

  yPosition -= 36;

  doc.rect(6, yPosition + 42, 72.5 - value * 3, 55);
  doc.rect(40.75 - value * 3, yPosition + 38, 36.75, 8, "FD");

  doc.rect(6, yPosition + 106, 72.5 - value * 3, 30);
  doc.rect(40.75 - value * 3, yPosition + 102, 36.75, 8, "FD");

  doc.setFontSize(13);

  doc.setTextColor(...bulletinColors.color2);

  text = selectedLevel > 9 ? "مدير)ة( المعهد" : "مدير)ة( المدرسة";
  doc.text(text, 45 - value * 3, yPosition + 43);

  doc.setTextColor(67, 62, 63);
  text = schoolDetails.headMaster;
  if (!affichageBulletin.hideDate || affichageBulletin.hideDate === false)
    text += "\n" + moment(bulletinDate).format("YYYY/MM/DD");
  doc.text(text, 30 - value * 3, yPosition + 51);

  doc.setTextColor(...bulletinColors.color2);
  doc.setFontSize(16);

  text = "إمضاء الولي";
  doc.text(text, 48 - value * 3, yPosition + 107);

  //stamp
  if (config.showStamp && stampImage && stampImage.src) {
    try {
      doc.addImage(stampImage, "PNG", 10, yPosition + 61, 30, 30);
    } catch (e) {}
  }
  //end stamp
};
//EDWEB-1180 harmonie school
//switch display personalized moy and spec/vsepc moyenne in pdf
const drawTrimesterMoyenneAndCardsHarmonie = function (
  doc,
  ctx,
  startY,
  moy,
  max,
  min,
  pedaMoyenne,
  personalizedMoyenne,
  affichageBulletin,
  stampImage,
  config,
  useTemplateSchool = null
) {
  const currentPageInfo = doc.getCurrentPageInfo();
  if (bulletinfOnTwoPages) doc.setPage(currentPageInfo.pageNumber - 1);
  const { selectedLevel, schoolDetails, bulletinDate, certifs, bulletinType } =
    config;
  const value = 5;
  let yPosition = startY + 68.2;

  let text;
  let width;
  //bulletin pilote and specifique have different fieldNames in paramétrage affichage
  let fieldVerySpecific = bulletinType == "MS" ? "verySpecific" : "specific";
  let fieldverySpecificName =
    bulletinType == "MS" ? "verySpecificName" : "specificName";
  if (affichageBulletin[fieldVerySpecific]) {
    doc.setFillColor(...bulletinColors.color5);
    doc.setDrawColor(...bulletinColors.color5);
    roundRect(
      ctx,
      45 - value * 2,
      yPosition,
      33.5 - value,
      8.2,
      {},
      true,
      false
    );

    doc.setFont("Amiri", "Bold");
    doc.setFontSize(11);
    text = affichageBulletin[fieldverySpecificName];
    doc.setTextColor(0xff, 0xff, 0xff);
    width = doc.getTextWidth(text);
    doc.text(text, 65.5 - value * 3 - width / 2, yPosition + 5.3);

    doc.setLineWidth(0.2);
    doc.setDrawColor(...bulletinColors.color2);
    doc.setFillColor(...bulletinColors.color6);

    roundRect(ctx, 6, yPosition, 38 - value * 2, 7.8, {}, true, true);
    roundRect(ctx, 6, yPosition, 19 - value, 7.8, {}, true, true);

    doc.setFont("Amiri", "Bold");
    doc.setFontSize(7);
    doc.setTextColor(...bulletinColors.color2);
    doc.text("أعلى\nمعدل بالقسم", 35 - (value + value / 2), yPosition + 3.3, {
      align: "center",
    });
    doc.text("أدنى\nمعدل بالقسم", 16.2 - value / 2, yPosition + 3.3, {
      align: "center",
    });

    // moyenne rect
    doc.setFillColor(...bulletinColors.color6);
    doc.setDrawColor(...bulletinColors.color2);
    doc.setLineWidth(0.15);
    doc.rect(45 - value * 2, yPosition + 9.3, 33.5 - value, 16.4, "FD");

    doc.setFillColor(...bulletinColors.color8);
    doc.rect(6, yPosition + 9.3, 19 - value, 16.4, "FD");
    doc.rect(25 - value, yPosition + 9.3, 19 - value, 16.4, "FD");

    // moyenne text
    doc.setTextColor(...bulletinColors.color4);
    if (useTemplateSchool == "CP") doc.setTextColor(255, 0, 0);
    doc.setFontSize(16);
    text = String(moy);
    width = doc.getTextWidth(text);
    doc.text(text, 63 - width / 2 - (value * 2 + value / 2), yPosition + 18.3);
    doc.setTextColor(...bulletinColors.color4);
    doc.setFontSize(13);
    text = String(max);
    width = doc.getTextWidth(text);
    doc.text(text, 34.75 - width / 2 - (value + value / 2), yPosition + 18.3);

    text = String(min);
    width = doc.getTextWidth(text);
    doc.text(text, 15.25 - width / 2 - value / 2, yPosition + 18.3);

    yPosition += 27; //99.2
  }

  if (affichageBulletin.personalized) {
    doc.setFillColor(...bulletinColors.color5);
    doc.setDrawColor(...bulletinColors.color2);
    roundRect(ctx, 6, yPosition, 73 - value * 3, 8.2, {}, true, false);

    doc.setFillColor(...bulletinColors.color6);
    doc.setDrawColor(...bulletinColors.color2);
    doc.setLineWidth(0.05);
    doc.rect(6, yPosition + 9, 73 - value * 3, 12, "FD");

    doc.setFontSize(10);
    text = affichageBulletin.personalizedName;
    width = doc.getTextWidth(text);
    doc.setTextColor(0xff, 0xff, 0xff);
    doc.text(text, 45 - width / 2 - value * 2, yPosition + 5.3);

    doc.setFontSize(16);
    width = doc.getTextWidth(personalizedMoyenne);
    doc.setTextColor(255, 0, 0);
    doc.text(personalizedMoyenne, 45 - width / 2 - value * 2, yPosition + 16.4);

    yPosition += 23.8; // 121
  }
  if (affichageBulletin.pedagogic) {
    doc.setFillColor(...bulletinColors.color5);
    doc.setDrawColor(...bulletinColors.color2);
    roundRect(ctx, 6, yPosition, 73 - value * 3, 8.2, {}, true, false);

    doc.setFillColor(...bulletinColors.color6);
    doc.setDrawColor(...bulletinColors.color2);
    doc.setLineWidth(0.05);

    doc.rect(6, yPosition + 9, 73 - value * 3, 8.2, "FD");

    doc.setFontSize(10);
    text = affichageBulletin.pedagogicName;
    width = doc.getTextWidth(text);
    doc.setTextColor(0xff, 0xff, 0xff);
    doc.text(text, 45 - width / 2 - value * 2, yPosition + 5.3);

    doc.setFontSize(11);
    text = pedaMoyenne ? String(pedaMoyenne) : "--";
    width = doc.getTextWidth(text);
    doc.setTextColor(...bulletinColors.color4);
    doc.text(text, 45 - width / 2 - value * 2, yPosition + 14.3);

    yPosition += 20; //110
  }

  //الشّهادة
  if (affichageBulletin.certif) {
    doc.setFillColor(...bulletinColors.color6);
    doc.setDrawColor(...bulletinColors.color2);

    yPosition -= 36;

    doc.rect(6, yPosition + 42, 72.5 - value * 3, 20);
    doc.rect(40.75 - value * 3, yPosition + 38, 36.75, 8, "FD");

    doc.setFontSize(13);
    doc.setTextColor(...bulletinColors.color2);

    text = "الشّهادة";
    doc.text(text, 55 - value * 3, yPosition + 43);
    doc.setTextColor(67, 62, 63);
    if (useTemplateSchool == "CP") doc.setTextColor(255, 0, 0);

    doc.text(
      getCertificationNameByMoyenne(moy, certifs),

      40 - value * 3,
      yPosition + 55
    );
    yPosition += 65;
  }
  //مدير)ة( المدرسة"
  doc.setLineWidth(0.15);

  doc.setFillColor(...bulletinColors.color6);
  doc.setDrawColor(...bulletinColors.color2);

  yPosition -= 36;

  doc.rect(6, yPosition + 42, 72.5 - value * 3, 55);
  doc.rect(40.75 - value * 3, yPosition + 38, 36.75, 8, "FD");

  doc.rect(6, yPosition + 106, 72.5 - value * 3, 30);
  doc.rect(40.75 - value * 3, yPosition + 102, 36.75, 8, "FD");

  doc.setFontSize(13);

  doc.setTextColor(...bulletinColors.color2);

  text = selectedLevel > 9 ? "مدير)ة( المعهد" : "مدير)ة( المدرسة";
  doc.text(text, 45 - value * 3, yPosition + 43);

  doc.setTextColor(67, 62, 63);
  text = schoolDetails.headMaster;
  if (!affichageBulletin.hideDate || affichageBulletin.hideDate === false)
    text += "\n" + moment(bulletinDate).format("YYYY/MM/DD");
  doc.text(text, 30 - value * 3, yPosition + 51);

  doc.setTextColor(...bulletinColors.color2);
  doc.setFontSize(16);

  text = "إمضاء الولي";
  doc.text(text, 48 - value * 3, yPosition + 107);

  //stamp
  if (config.showStamp && stampImage && stampImage.src) {
    try {
      doc.addImage(stampImage, "PNG", 10, yPosition + 61, 30, 30);
    } catch (e) {}
  }
  //end stamp
};
const drawTrimesterMoyenneAndCardsArcEnciel = async function (
  doc,
  ctx,
  startY,
  moy,
  max,
  min,
  pedaMoyenne,
  personalizedMoyenne,
  affichageBulletin,
  stampImage,
  qrImage,
  config
) {
  const currentPageInfo = doc.getCurrentPageInfo();
  if (bulletinfOnTwoPages) doc.setPage(currentPageInfo.pageNumber - 1);
  const { selectedLevel, schoolDetails, bulletinDate, certifs } = config;
  const value = 5;
  let yPosition = startY + 68.2;

  let text;
  let width;

  if (affichageBulletin.verySpecific || affichageBulletin.specific) {
    doc.setFillColor(...bulletinColors.color5);
    doc.setDrawColor(...bulletinColors.color5);
    roundRect(
      ctx,
      45 - value * 2,
      yPosition,
      33.5 - value,
      8.2,
      {},
      true,
      false
    );

    doc.setFont("Amiri", "Bold");
    doc.setFontSize(11);
    text = affichageBulletin.verySpecificName;
    doc.setTextColor(0xff, 0xff, 0xff);
    width = doc.getTextWidth(text);
    doc.text(text, 65.5 - value * 3 - width / 2, yPosition + 5.3);

    doc.setLineWidth(0.2);
    doc.setFillColor(...bulletinColors.color6);
    doc.setDrawColor(...bulletinColors.color2);

    roundRect(ctx, 6, yPosition, 38 - value * 2, 7.8, {}, true, true);
    roundRect(ctx, 6, yPosition, 19 - value, 7.8, {}, true, true);

    doc.setFont("Amiri", "Bold");
    doc.setFontSize(7);
    doc.setTextColor(...bulletinColors.color2);
    doc.text("أعلى\nمعدل بالقسم", 35 - (value + value / 2), yPosition + 3.3, {
      align: "center",
    });
    doc.text("أدنى\nمعدل بالقسم", 16.2 - value / 2, yPosition + 3.3, {
      align: "center",
    });

    // moyenne rect
    doc.setFillColor(...bulletinColors.color6);
    doc.setDrawColor(...bulletinColors.color2);
    doc.setLineWidth(0.15);
    doc.rect(45 - value * 2, yPosition + 9.3, 33.5 - value, 16.4, "FD");

    doc.setFillColor(...bulletinColors.color8);
    doc.rect(6, yPosition + 9.3, 19 - value, 16.4, "FD");
    doc.rect(25 - value, yPosition + 9.3, 19 - value, 16.4, "FD");

    // moyenne text
    doc.setTextColor(...bulletinColors.color4);
    doc.setFontSize(16);
    text = String(moy);
    width = doc.getTextWidth(text);
    doc.text(text, 63 - width / 2 - (value * 2 + value / 2), yPosition + 18.3);

    doc.setFontSize(13);
    text = String(max);
    width = doc.getTextWidth(text);
    doc.text(text, 34.75 - width / 2 - (value + value / 2), yPosition + 18.3);

    text = String(min);
    width = doc.getTextWidth(text);
    doc.text(text, 15.25 - width / 2 - value / 2, yPosition + 18.3);

    yPosition += 27; //99.2
  }

  if (affichageBulletin.pedagogic) {
    doc.setFillColor(...bulletinColors.color5);
    doc.setDrawColor(...bulletinColors.color2);
    roundRect(ctx, 6, yPosition, 73 - value * 3, 8.2, {}, true, false);

    doc.setFillColor(...bulletinColors.color6);
    doc.setDrawColor(...bulletinColors.color2);
    doc.setLineWidth(0.05);

    doc.rect(6, yPosition + 9, 73 - value * 3, 8.2, "FD");

    doc.setFontSize(10);
    text = affichageBulletin.pedagogicName;
    width = doc.getTextWidth(text);
    doc.setTextColor(0xff, 0xff, 0xff);
    doc.text(text, 45 - width / 2 - value * 2, yPosition + 5.3);

    doc.setFontSize(11);
    text = pedaMoyenne ? String(pedaMoyenne) : "--";
    width = doc.getTextWidth(text);
    doc.setTextColor(...bulletinColors.color4);
    doc.text(text, 45 - width / 2 - value * 2, yPosition + 14.3);

    yPosition += 20; //110
  }

  if (affichageBulletin.personalized) {
    doc.setFillColor(...bulletinColors.color5);
    doc.setDrawColor(...bulletinColors.color2);
    roundRect(ctx, 6, yPosition, 73 - value * 3, 8.2, {}, true, false);

    doc.setFillColor(...bulletinColors.color6);
    doc.setDrawColor(...bulletinColors.color2);
    doc.setLineWidth(0.05);
    doc.rect(6, yPosition + 9, 73 - value * 3, 8.2, "FD");

    doc.setFontSize(10);
    text = affichageBulletin.personalizedName;
    width = doc.getTextWidth(text);
    doc.setTextColor(0xff, 0xff, 0xff);
    doc.text(text, 45 - width / 2 - value * 2, yPosition + 5.3);

    doc.setFontSize(11);
    width = doc.getTextWidth(personalizedMoyenne);
    doc.setTextColor(...bulletinColors.color4);
    doc.text(personalizedMoyenne, 45 - width / 2 - value * 2, yPosition + 14.5);

    yPosition += 20; // 121
  }
  //الشّهادة
  if (affichageBulletin.certif) {
    doc.setFillColor(...bulletinColors.color6);
    doc.setDrawColor(...bulletinColors.color2);

    yPosition -= 36;

    doc.rect(6, yPosition + 42, 72.5 - value * 3, 20);
    doc.rect(40.75 - value * 3, yPosition + 38, 36.75, 8, "FD");

    doc.setFontSize(13);

    doc.setTextColor(...bulletinColors.color2);

    text = "الشّهادة";
    doc.text(text, 55 - value * 3, yPosition + 43);

    doc.setTextColor(67, 62, 63);

    doc.text(
      getCertificationNameByMoyenne(moy, certifs),

      40 - value * 3,
      yPosition + 55
    );
    yPosition += 65;
  }
  //مدير)ة( المدرسة"
  doc.setLineWidth(0.15);

  doc.setFillColor(...bulletinColors.color6);
  doc.setDrawColor(...bulletinColors.color2);

  yPosition -= 36;

  doc.rect(6, yPosition + 42, 72.5 - value * 3, 55);
  doc.rect(40.75 - value * 3, yPosition + 38, 36.75, 8, "FD");

  doc.rect(6, yPosition + 106, 72.5 - value * 3, 30);
  doc.rect(40.75 - value * 3, yPosition + 102, 36.75, 8, "FD");

  doc.setFontSize(13);

  doc.setTextColor(...bulletinColors.color2);

  text = selectedLevel > 9 ? "مدير)ة( المعهد" : "مدير)ة( المدرسة";
  doc.text(text, 45 - value * 3, yPosition + 43);

  doc.setTextColor(67, 62, 63);
  text = schoolDetails.headMaster;
  if (!affichageBulletin.hideDate || affichageBulletin.hideDate === false)
    text += "\n" + moment(bulletinDate).format("YYYY/MM/DD");
  doc.text(text, 30 - value * 3, yPosition + 51);

  doc.setTextColor(...bulletinColors.color2);
  doc.setFontSize(16);

  text = "إمضاء الولي";
  doc.text(text, 48 - value * 3, yPosition + 107);

  //stamp
  if (config.showStamp && stampImage && stampImage.src) {
    try {
      doc.addImage(stampImage, "PNG", 10, yPosition + 61, 30, 30);
    } catch (e) {}
  }
  //end stamp
  // QR code
  if (qrImage && qrImage.src) {
    try {
      doc.addImage(qrImage, "PNG", 10, yPosition + 150, 45, 45);
    } catch (e) {
      console.info(e);
    }
  }
};

const drawModuleFooter = function (doc, ctx, startY, avg) {
  let x = 158;
  let y = startY;
  let width = 35.5;
  doc.setFillColor(...bulletinColors.color3);
  doc.setDrawColor(...bulletinColors.color3);
  doc.rect(x, y, width + 10, 8.5, "FD");

  x = 111;
  y = startY + 0.4;
  width = 45.5;
  doc.setFillColor(255, 255, 255);

  roundRect(ctx, x, y, width, 7.8, {}, true, true);

  // footer text (moyenne)
  doc.setFont("Noto Sans Arabic", "Bold");
  doc.setFontSize(13);
  let text = "معدل المجال";
  doc.setTextColor(0xff, 0xff, 0xff);
  doc.text(text, 169, startY + 5.5);

  doc.setFont("Amiri", "Bold");
  doc.setFontSize(14);
  text = String(avg);
  doc.setTextColor(...bulletinColors.color4);
  width = doc.getTextWidth(text);
  doc.text(text, 134.75 - width / 2, startY + 5.5);
};

const drawBulletinpythagore = async function (
  doc,
  bulletinData,
  affichageBulletin,
  config
) {
  const { selectedTrimester, settings, schoolarYearName, selectedLevel } =
    config;
  let topMargin = -15;
  const pdf_width = doc.internal.pageSize.width;
  let width = 0;

  let arrayOfImages = settings.image;
  let imgData;
  let buildingName = "";

  // logo
  try {
    for (var i = 0; i < arrayOfImages.length; i++) {
      if (settings.activeBuilding === arrayOfImages[i].db) {
        imgData = new Image();
        imgData.src = arrayOfImages[i].logo;
        buildingName = arrayOfImages[i].name;
      }
    }
    doc.addImage(imgData, "JPEG", pdf_width - 30, 5, 20, 20);
  } catch (error) {
    console.info("Logo not found");
  }

  doc.setFont("Amiri", "normal");

  doc.setTextColor(99, 87, 91);
  doc.setFontSize(12);
  let text = "École : " + buildingName;
  doc.text(text, 15, 12);
  text = "Année scolaire : " + schoolarYearName;
  doc.text(text, 15, 20);

  const className = bulletinData.classroomName;

  // student classroom
  text = "Classe : " + className;
  doc.text(text, 110, 12);

  // student name
  text = "Élève : " + bulletinData.fullName;
  doc.text(text, 110, 20);

  // trimester text
  doc.setFontSize(20);
  doc.setFont("Amiri", "normal");
  doc.setTextColor(67, 62, 63);
  let title = "";
  switch (selectedTrimester) {
    case "1":
      title = "Relevé des notes - 1er trimestre";
      break;
    case "2":
      title = "Relevé des notes - 2ème trimestre";
      break;
    case "3":
      title = "Relevé des notes - 3ème trimestre";
      break;
  }
  width = doc.getTextWidth(title);
  doc.text(title, pdf_width / 2 - width / 2, 37);

  const cellToDelete = {};
  // Show appreciation instead of moyenne for levels 1 and 2
  const affichageMoy =
    selectedLevel && (selectedLevel === 1 || selectedLevel === 2)
      ? false
      : affichageBulletin.moyModule;

  bulletinData.modules.forEach((m) => {
    let arr = [];
    let array = [];

    m.subjects.forEach((c) => {
      arr[0] = {
        content: c.name,
        styles: {
          halign: "center",
          valign: "middle",
          fillColor: "#55a1f1",
          fontSize: 10,
          textColor: "#ffffff",
        },
      };

      arr[1] = {
        content: c.coef,
        styles: {
          halign: "center",
          valign: "middle",
          fillColor: "#55a1f1",
          fontSize: 10,
          textColor: "#ffffff",
        },
      };
      arr[2] = {
        content: c.mark ?? "--",
        styles: {
          halign: "center",
          fontSize: 10,
          valign: "middle",
        },
      };

      //best mark
      arr[3] = {
        content: c.max,
        styles: {
          halign: "center",
          valign: "middle",
          fontSize: 10,
        },
      };

      //lowest mark
      arr[4] = {
        content: c.min,
        styles: {
          halign: "center",
          valign: "middle",
          fontSize: 10,
        },
      };

      arr[5] = cellToDelete;
      array.push(arr);
      arr = [];
    });
    if (affichageMoy)
      //end shifting
      array[0][5] = {
        halign: "center",
        valign: "middle",
        fontSize: 13,
        content: m.moyenne,
        rowSpan: array.length,
      };
    else {
      //affichage appréciation
      array[0][5] = {
        halign: "center",
        valign: "middle",
        fontSize: 13,
        content: m.appreciation ? m.appreciation.trim() : "",
        rowSpan: array.length,
      };
    }
    for (let row = 0; row < array.length; row++) {
      array[row] = array[row].filter((cell) => cell !== cellToDelete);
    }

    doc.autoTable({
      startY: 60 + topMargin,
      theme: "grid",
      styles: {
        font: "Amiri",
        halign: "center",
        valign: "middle",
        fontStyle: "Bold",
      },
      cellPadding: 0,
      headStyles: {
        fillColor: "#3e93ef",
        fontSize: 10,
        lineColor: [255, 255, 255],
        lineWidth: 0.1,
      },
      head: [
        [
          {
            content: m.name,
            colSpan: 6,
            styles: {
              halign: "center",
              fillColor: "#187fec",
              lineColor: [255, 255, 255],
              fontSize: 15,
              textColor: "#ffffff",
              font: /[a-zA-Z]/.test(m.name) ? "Amiri" : "Noto Sans Arabic",
              fontStyle: "Bold",
            },
          },
        ],
        affichageMoy
          ? [
              "Matière",
              "Coef",
              "Note",
              "Note la plus haute",
              "Note la plus basse",
              "Moyenne de module",
            ]
          : [
              "Matière",
              "Coef",
              "Note",
              "Note la plus haute",
              "Note la plus basse",
              "Appréciation",
            ],
      ],
      body: array,
      margin: {},
      didDrawPage: function (data) {
        // Reseting top margin. The change will be reflected only after print the first page.
        data.settings.margin.top = 10;
        bulletinfOnTwoPages = doc.internal.getNumberOfPages() > studentCount;
      },
      columnStyles: affichageMoy
        ? {
            0: { cellWidth: 58 },
            1: { cellWidth: 14 },
            2: { cellWidth: 15 },
            3: { cellWidth: 30 },
            4: { cellWidth: 30 },
            5: { cellWidth: 34 },
          }
        : {
            0: { cellWidth: 60 },
            1: { cellWidth: 12 },
            2: { cellWidth: 14 },
            3: { cellWidth: 25 },
            4: { cellWidth: 25 },
            5: { cellWidth: 46 },
          },
    });

    topMargin = doc.lastAutoTable.finalY - 55;
  });

  if (affichageBulletin.specific) {
    doc.autoTable({
      startY: 60 + topMargin,
      theme: "grid",
      styles: {
        font: "Amiri",
        halign: "center",
        valign: "middle",
        fontStyle: "Bold",
        fontSize: 14,
      },
      cellPadding: 0,
      headStyles: {
        fontSize: 11,
        lineColor: [255, 255, 255],
        lineWidth: 0.1,
      },
      head: [
        [
          affichageBulletin.specificName,
          "Moyenne la plus haute",
          "Moyenne la plus basse",
        ],
      ],
      body: [
        [
          bulletinData.moyenne,
          bulletinData.maxMoyenne,
          bulletinData.minMoyenne,
        ],
      ],
      didDrawPage: function (data) {
        // Reseting top margin. The change will be reflected only after print the first page.
        data.settings.margin.top = 10;
        bulletinfOnTwoPages = doc.internal.getNumberOfPages() > studentCount;
      },

      columnStyles: {
        0: { cellWidth: 61 },
        1: { cellWidth: 61 },
        2: { cellWidth: 61 },
      },
    });

    topMargin = doc.lastAutoTable.finalY - 55;
  }

  if (affichageBulletin.pedagogic || affichageBulletin.personalized) {
    let perMoyenne = "--";
    if (
      affichageBulletin.personalized &&
      bulletinData.pedaMoyenne != "--" &&
      bulletinData.moyenne != "--"
    ) {
      const totalCoef =
        affichageBulletin.pedagogicCoef + affichageBulletin.specificCoef;
      perMoyenne = String(
        (
          (Number(bulletinData.moyenne) * affichageBulletin.specificCoef +
            Number(bulletinData.pedaMoyenne) *
              affichageBulletin.pedagogicCoef) /
          totalCoef
        ).toFixed(2)
      );
    }
    const head = [];
    const body = [];
    let tableWidth = 0;
    if (affichageBulletin.pedagogic) {
      head.push(affichageBulletin.pedagogicName);
      body.push(bulletinData.pedaMoyenne);
      tableWidth += 61;
    }

    if (affichageBulletin.personalized) {
      head.push(affichageBulletin.personalizedName);
      body.push(perMoyenne);
      tableWidth += 61;
    }

    let margin = (pdf_width - tableWidth) / 2;

    doc.autoTable({
      startY: 60 + topMargin,
      theme: "grid",
      styles: {
        font: "Amiri",
        halign: "center",
        valign: "middle",
        fontStyle: "Bold",
        fontSize: 14,
      },
      cellPadding: 0,
      headStyles: {
        fontSize: 11,
        lineColor: [255, 255, 255],
        lineWidth: 0.1,
      },
      head: [head],
      body: [body],
      margin: { left: margin, right: margin },
      didDrawPage: function (data) {
        // Reseting top margin. The change will be reflected only after print the first page.
        data.settings.margin.top = 10;
        bulletinfOnTwoPages = doc.internal.getNumberOfPages() > studentCount;
      },

      columnStyles: {
        0: { cellWidth: 61 },
        1: { cellWidth: 61 },
      },
    });

    topMargin = doc.lastAutoTable.finalY - 55;
  }
};
const drawBulletin = async function (
  doc,
  ctx,
  student,
  bulletinData,
  affichageBulletin,
  stampImage,
  config,
  useTemplateSchool = false
) {
  const {
    selectedLevel,
    schoolDetails,
    selectedClassName,
    schoolarYearName,
    selectedTrimester,
    studentsData,
    settings,
  } = config;
  const studentInfo = studentsData.find((s) => s._id == student.id);
  const studentPhoto =
    affichageBulletin.photo && studentInfo && studentInfo.photo;
  bulletinColors = {
    color1: [70, 187, 239],
    color2: [64, 187, 239],
    color3: [16, 169, 229],
    color4: [67, 62, 63],
    color5: [52, 172, 215],
    color6: [224, 237, 243],
    color7: [64, 187, 239],
    color8: [225, 225, 226],
    color9: [224, 237, 243],
  };
  const record = student;

  const pdf_width = doc.internal.pageSize.width;
  let width = 0;
  let width2 = 0;

  // ending header line
  doc.setDrawColor(...bulletinColors.color5);
  doc.setLineWidth(0.8);
  doc.line(0, 45 - 12, pdf_width, 45 - 12);

  // trimester rounded rect title
  doc.setDrawColor(...bulletinColors.color5);
  doc.setLineWidth(0.4);
  doc.setFillColor(255, 255, 255);
  doc.roundedRect(pdf_width / 2 - 25, 39 - 12, 50, 12, 4, 4, "FD");

  // trimester text
  doc.setFontSize(16);
  doc.setFont("Amiri", "normal");
  doc.setTextColor(...bulletinColors.color5);
  let title = "";
  switch (selectedTrimester) {
    case "1":
      title = "الثلاثي الأول";
      break;
    case "2":
      title = "الثلاثي الثاني";
      break;
    case "3":
      title = "الثلاثي الثالث";
      break;
  }
  doc.setFont("Noto Sans Arabic", "Bold");
  width = doc.getTextWidth(title);
  doc.text(title, pdf_width / 2 - width / 2, 46.2 - 12);

  if (studentPhoto) {
    const className = selectedClassName;

    let studentLabel = record.gender === "male" ? " : التلميذ" : " : التلميذة";

    // student name
    doc.setFont("Amiri", "normal");
    doc.setFontSize(12);

    doc.setTextColor(0, 0, 0);

    let schoolTypeText =
      selectedLevel <= 9 ? " المدرسة الابتدائيّة " : "  المعهد الخاص ";
    if (
      settings.base_url == "https://nms.api.edtrust.tn" &&
      schoolDetails.dbName == "school3"
    )
      schoolTypeText = "  المعهد الخاص ";
    text = schoolDetails.ArabicName
      ? schoolTypeText + schoolDetails.ArabicName
      : "................................................... : المدرسة الابتدائيّة";
    width = doc.getTextWidth(text);
    doc.text(text, pdf_width - width - 12, 12);
    text = ` ${schoolarYearName}  : السنة الدراسيّة`;
    width = doc.getTextWidth(text);
    doc.setFontSize(13);
    doc.text(text, 10, 22); // this for schoolarYearName
    doc.setFontSize(12);

    let text = studentLabel;
    width = doc.getTextWidth(text);
    doc.text(text, pdf_width - width - 12, 17.5);

    text = student.fullName;
    doc.setFont("Amiri", "normal");
    doc.setFontSize(13);
    width2 = doc.getTextWidth(text);
    doc.text(text, pdf_width - width - width2 - 12, 17.5);
    doc.setFont("Amiri", "normal");
    doc.setFontSize(12);
    // identUnique
    if (affichageBulletin.identUnique && student.uniqueId) {
      text = " : المعرف الوحيد";
      width = doc.getTextWidth(text);
      doc.text(text, pdf_width - width - 12, 23);
      text = String(student.uniqueId);
      width2 = doc.getTextWidth(text);
      doc.text(text, pdf_width - width2 - width - 12, 23);
    }

    // BirthDate
    if (student.BirthDate) {
      text = " : تاريخ الولادة";
      width = doc.getTextWidth(text);
      doc.text(text, pdf_width - width - 12, 28.5);
      text = moment(student.BirthDate).format("DD/MM/YYYY");
      width2 = doc.getTextWidth(text);
      doc.text(text, pdf_width - width2 - width - 12, 28.5);
    }

    // student classroom
    const yCoord = 34;
    text = " : القسم";
    doc.setTextColor(0, 0, 0);
    width = doc.getTextWidth(text);
    width2 = doc.getTextWidth(className);
    doc.text(text, pdf_width - width - 12, yCoord); // this for className
    doc.text(className, pdf_width - width - width2 - 13, yCoord); // this for className
    // number of students in classroom
    if (affichageBulletin.numberStudentInsc) {
      // this for numberStudentInsc
      text = String(
        Array.isArray(bulletinData.students)
          ? bulletinData.students.length
          : bulletinData.students
      );
      width2 = doc.getTextWidth(text);
      doc.text(text, 10, yCoord); // this for numberStudentInsc
      text = " : عدد التلاميذ المرسّمين";
      width = doc.getTextWidth(text);
      doc.text(text, 10 + width2, yCoord); // this for numberStudentInsc
    }
  } else {
    const className = selectedClassName;

    let studentLabel = record.gender === "male" ? " : التلميذ" : " : التلميذة";

    // student name
    doc.setFont("Amiri", "normal");
    doc.setFontSize(12);

    doc.setTextColor(0, 0, 0);

    let schoolTypeText =
      selectedLevel <= 9 ? " المدرسة الابتدائيّة " : "  المعهد الخاص ";
    if (
      settings.base_url == "https://nms.api.edtrust.tn" &&
      schoolDetails.dbName == "school3"
    )
      schoolTypeText = "  المعهد الخاص ";
    text = schoolDetails.ArabicName
      ? schoolDetails.ArabicName
      : "..............................";
    let schoolNameWidth = doc.getTextWidth(schoolDetails.ArabicName);

    width = doc.getTextWidth(schoolTypeText);
    width2 = doc.getTextWidth(text);
    doc.text(schoolTypeText, pdf_width - width - 11, 12);
    doc.text(text, pdf_width - width - width2 - 11, 12);

    text = ` ${schoolarYearName}  : السنة الدراسيّة`;
    width = doc.getTextWidth(text);
    doc.text(text, 10, 12); // this for schoolarYearName

    let text = studentLabel;
    width = doc.getTextWidth(text);
    doc.text(text, pdf_width - width - 12, 17.5);

    text = student.fullName;
    doc.setFont("Amiri", "normal");
    doc.setFontSize(13);
    width2 = doc.getTextWidth(text);
    doc.text(text, pdf_width - width - width2 - 12, 17.5);
    doc.setFont("Amiri", "normal");
    doc.setFontSize(12);
    // student classroom
    text = " : القسم";
    doc.setTextColor(0, 0, 0);
    width = doc.getTextWidth(text);
    width2 = doc.getTextWidth(className);
    doc.text(text, 13 + width2, 17.5); // this for className
    doc.text(className, 12, 17.5); // this for className
    // number of students in classroom
    if (affichageBulletin.numberStudentInsc) {
      // this for numberStudentInsc
      text = String(
        Array.isArray(bulletinData.students)
          ? bulletinData.students.length
          : bulletinData.students
      );
      width2 = doc.getTextWidth(text);
      doc.text(text, 12, 23); // this for numberStudentInsc
      text = " : عدد التلاميذ المرسّمين";
      width = doc.getTextWidth(text);
      doc.text(text, 12 + width2, 23); // this for numberStudentInsc
    }

    // BirthDate
    if (student.BirthDate) {
      text = " : تاريخ الولادة";
      width = doc.getTextWidth(text);
      doc.text(text, pdf_width - width - 12, 23);
      text = moment(student.BirthDate).format("DD/MM/YYYY");
      width2 = doc.getTextWidth(text);
      doc.text(text, pdf_width - width2 - width - 12, 23);
    }

    // identUnique
    if (affichageBulletin.identUnique && student.uniqueId) {
      text = " : المعرف الوحيد";
      width = doc.getTextWidth(text);
      doc.text(text, pdf_width - width - 12, 28.5);
      text = String(student.uniqueId);
      width2 = doc.getTextWidth(text);
      doc.text(text, pdf_width - width2 - width - 12, 28.5);
    }
  }
  //let showSubModule;
  //if (affichageBulletin.showSubModule)
  //  showSubModule = affichageBulletin.showSubModule;
  const cellToDelete = {};
  let topMargin = -21;
  let tableSize = 9;
  switch (selectedLevel) {
    case 1:
      tableSize = 11.5;
      break;
    case 2:
      tableSize = 11.5;
      break;
    case 3:
      tableSize = 10.5;
      break;
    case 4:
      tableSize = 9.5;
      break;
    default:
      break;
  }

  let totatPts = student.modules.length * 2;
  student.modules.forEach((m) => {
    m.subjects.forEach((c) => {
      if (c.subSubjectMarks) totatPts += c.subSubjectMarks.length * 0.75;
      else totatPts++;
    });
  });
  if (totatPts > totalPtsTreshold) tableSize *= 0.9;
  let longNameSubject = false;
  student.modules.forEach((m) => {
    //try {
    let arr = [];
    let array = [];

    drawModuleHeader(
      doc,
      ctx,
      topMargin,
      m.name,
      affichageBulletin.hideSubject && affichageBulletin.hideSubject === true
    );
    topMargin += 17.2;

    m.subjects.forEach((c) => {
      let subFormattedName = c.name;
      if (c.name.length > 20 && c.name.indexOf(" و ") != -1) {
        let indexOfFirst = c.name.indexOf(" و ");
        subFormattedName =
          c.name.slice(0, indexOfFirst) + "\n" + c.name.slice(indexOfFirst);
      }
      if (c.length > 22) longNameSubject = true;

      const spanNumber = c.subSubjectMarks ? c.subSubjectMarks.length : 1;
      const colspan =
        spanNumber == 1
          ? c.subSubjectMarks && c.subSubjectMarks.length > 0
            ? 1
            : 3
          : 1;
      const lines = subFormattedName.split("\n");
      //Get the maximum length
      const maxLength = Math.max(...lines.map((line) => line.length));
      let subjectFontSize = maxLength >= 12 ? tableSize * 0.8 : tableSize + 1;
      //let subjectFontSize =
      //  subFormattedName.length >= 23 ? tableSize * 0.8 : tableSize + 1;
      if (
        !affichageBulletin.hideSubject ||
        affichageBulletin.hideSubject === false ||
        (affichageBulletin.hideSubject &&
          affichageBulletin.hideSubject === true &&
          (!c.subSubjectMarks || c.subSubjectMarks.length == 0))
      )
        arr[8] = {
          content: subFormattedName,
          rowSpan: spanNumber,
          colSpan: colspan,
          styles: {
            halign: "right",
            valign: "middle",
            fillColor: rgbToHex(...bulletinColors.color7),
            fontSize: subjectFontSize,
            textColor: "#ffffff",
            lineColor: [229, 229, 229],
            cellPadding: {
              top: 0.8,
              bottom: 0.8,
              right: 2,
              left: 1,
            },
          },
        };
      // ﺍﻟﻌﺪﺩ / 20
      if (
        !affichageBulletin.hideSubject ||
        affichageBulletin.hideSubject === false ||
        !c.subSubjectMarks ||
        c.subSubjectMarks.length == 0
      )
        arr[5] = {
          content: c.mark ?? "--",
          rowSpan: spanNumber,
          styles: {
            halign: "center",
            valign: "middle",
            fontSize: totatPts > totalPtsTreshold ? 9 : 10,
            lineColor: [...bulletinColors.color5],
            cellPadding: 0,
          },
        };

      arr[4] = cellToDelete;
      arr[3] = cellToDelete;
      arr[2] = cellToDelete;

      //best mark
      arr[1] = {
        content: c.max,
        rowSpan: spanNumber,
        styles: {
          halign: "center",
          valign: "middle",
          fontSize: totatPts > totalPtsTreshold ? 8 : 9,
          cellPadding: 0,
          lineColor: [...bulletinColors.color5],
        },
      };

      //lowest mark
      arr[0] = {
        content: c.min,
        rowSpan: spanNumber,
        styles: {
          halign: "center",
          valign: "middle",
          fontSize: 9,
          cellPadding: 0,
          lineColor: [...bulletinColors.color5],
        },
      };
      //sub subjects
      if (c.subSubjectMarks)
        c.subSubjectMarks.forEach((subs) => {
          try {
            arr[6] = {
              content: subs.mark,
              styles: {
                halign: "center",
                valign: "middle",
                cellPadding: 0,
                fontSize:
                  affichageBulletin.hideSubject &&
                  affichageBulletin.hideSubject === true
                    ? totatPts > totalPtsTreshold
                      ? 9
                      : 10
                    : totatPts > totalPtsTreshold
                    ? 7
                    : 8,
                fillColor:
                  affichageBulletin.hideSubject &&
                  affichageBulletin.hideSubject === true
                    ? "#ffffff"
                    : rgbToHex(...bulletinColors.color9),
                //textColor:
                //  affichageBulletin.hideSubject &&
                //  affichageBulletin.hideSubject === true
                //    ? "#000000"
                //    : rgbToHex(...bulletinColors.color2),
                lineColor:
                  affichageBulletin.hideSubject &&
                  affichageBulletin.hideSubject === true
                    ? [...bulletinColors.color5]
                    : bulletinColors.color2,
              },
            }; //[229, 229, 229]
            const arabicLetters = /[\u0600-\u06FF]/;
            const arabicName = arabicLetters.test(subs.name);
            let ssName = arabicName
              ? "المحفوظات".length <= subs.name.length
                ? //"(/" + subs.name + ")" + subs.maxMark
                  subs.name + "\n" + "(/" + subs.maxMark + ")"
                : "(/" + subs.maxMark + ")  " + subs.name
              : subs.name + "(/" + subs.maxMark + ")";
            arr[7] = {
              content: ssName,
              colSpan:
                affichageBulletin.hideSubject &&
                affichageBulletin.hideSubject === true
                  ? 3
                  : 1,
              styles: {
                halign: "right",
                valign: "middle",
                cellPadding: 0.5,
                fontSize:
                  affichageBulletin.hideSubject &&
                  affichageBulletin.hideSubject === true
                    ? ssName.length > 20
                      ? tableSize * 0.8
                      : tableSize
                    : totatPts > totalPtsTreshold
                    ? 6
                    : 7,
                fillColor:
                  affichageBulletin.hideSubject &&
                  affichageBulletin.hideSubject === true
                    ? rgbToHex(...bulletinColors.color7)
                    : rgbToHex(...bulletinColors.color9),
                textColor:
                  affichageBulletin.hideSubject &&
                  affichageBulletin.hideSubject === true
                    ? "#ffffff"
                    : rgbToHex(...bulletinColors.color2),
                lineColor:
                  affichageBulletin.hideSubject &&
                  affichageBulletin.hideSubject === true
                    ? [229, 229, 229]
                    : bulletinColors.color2,
              },
            };
            array.push(arr);
            arr = [];
          } catch (error) {
            console.error("error sub", error);
          }
        });
      else array.push(arr);
      arr = [];
    });

    //end shifting

    array[0][2] = {
      content: "",
      rowSpan: array.length,
      styles: {
        lineColor: [...bulletinColors.color5],
      },
    };
    //appreciation

    if (
      !m.submodules ||
      m.submodules.every((submodule) => submodule.NbSubjects === 0)
    ) {
      array[0][3] = {
        content: m.appreciation.trim(),
        rowSpan: array.length,
        styles: {
          halign: "center",
          valign: "middle",
          fontSize: m.appreciation.length > 15 ? 10 : 12,
          lineColor: [...bulletinColors.color5],
          cellPadding: 1,
        },
      };
    } else {
      let appIndex = 0;
      m.submodules.forEach((subModule) => {
        const subSubjectCount = m.subjects
          .filter((subject) => subject.submoduleID === subModule._id)
          .reduce(
            (acc, subject) =>
              acc +
              (subject.subSubjectMarks ? subject.subSubjectMarks.length : 1),
            0
          );
        let rowNumber = subSubjectCount;
        array[appIndex][3] = {
          content: subModule.appreciation.trim(),
          rowSpan: rowNumber,
          styles: {
            cellPadding: 1,
            halign: "center",
            valign: "middle",
            fontSize: 10,
            lineColor: [52, 172, 215],
          },
        };
        appIndex += rowNumber;
      });
    }
    //ﻣﻌﺪﻝ ﺍﻟﻤﺠﺎﻝ
    array[0][4] = {
      content: m.moyenne,
      rowSpan: array.length,
      styles: {
        halign: "center",
        fontSize: totatPts > totalPtsTreshold ? 11 : 12,
        valign: "middle",
        lineColor: [...bulletinColors.color5],
      },
    };

    for (let row = 0; row < array.length; row++) {
      array[row] = array[row].filter((cell) => cell !== cellToDelete);
    }
    const bodyStyles =
      totatPts > totalPtsTreshold
        ? {
            minCellHeight: 5,
            cellPadding: { vertical: 1, horizontal: 1 },
          }
        : {};
    doc.autoTable({
      startY: 64 + topMargin,
      theme: "grid",
      styles: {
        font: "Amiri",
        halign: "center",
        fontStyle: "Bold",
      },
      bodyStyles: bodyStyles,
      cellPadding: 0,
      body: array,
      margin: { left: 65, bottom: 0 },
      didDrawPage: function (data) {
        // Reseting top margin. The change will be reflected only after print the first page.
        data.settings.margin.top = 10;
        bulletinfOnTwoPages = doc.internal.getNumberOfPages() > studentCount;
      },
      columnStyles: {
        0: { cellWidth: 10 },
        1: { cellWidth: 10 },
        2: { cellWidth: 1 },
        3: { cellWidth: 38 },
        4: { cellWidth: 15 },
        5: { cellWidth: 16 },
        6: { cellWidth: 8 },
        7: { cellWidth: 18 },
        8: { cellWidth: longNameSubject ? 25 : 23 },
      },
    });

    topMargin = doc.lastAutoTable.finalY - 61.4 + 2;
    /*} catch (error) {
        console.error("error.stack");

        console.trace(error);
      }*/
  });
  if (useTemplateSchool && useTemplateSchool == "HC")
    drawTrimesterMoyenneAndCardsHarmonie(
      doc,
      ctx,
      -27,
      student.moyenne,
      bulletinData.maxMoyenne,
      bulletinData.minMoyenne,
      student.pedaMoyenne,
      student.personalizedMoyenne,
      affichageBulletin,
      stampImage,
      config
    );
  else
    drawTrimesterMoyenneAndCards(
      doc,
      ctx,
      -27,
      student.moyenne,
      bulletinData.maxMoyenne,
      bulletinData.minMoyenne,
      student.pedaMoyenne,
      student.personalizedMoyenne,
      affichageBulletin,
      stampImage,
      config
    );
};

const drawBulletinEverest = async function (
  doc,
  ctx,
  student,
  bulletinData,
  affichageBulletin,
  stampImage,
  config
) {
  const {
    selectedLevel,
    schoolDetails,
    selectedClassName,
    schoolarYearName,
    selectedTrimester,
    studentsData,
  } = config;
  const studentInfo = studentsData.find((s) => s._id == student.id);
  const studentPhoto =
    affichageBulletin.photo && studentInfo && studentInfo.photo;
  bulletinColors = {
    color1: [70, 187, 239],
    color2: [64, 187, 239],
    color3: [16, 169, 229],
    color4: [67, 62, 63],
    color5: [52, 172, 215],
    color6: [224, 237, 243],
    color7: [64, 187, 239],
    color8: [225, 225, 226],
    color9: [224, 237, 243],
  };
  const record = student;

  const pdf_width = doc.internal.pageSize.width;
  let width = 0;
  let width1 = 0;
  let width2 = 0;
  // Header texts
  //الجمهورية التونسية وزارة التربية
  doc.setFont("PT Bold Heading", "Bold");
  doc.setFontSize(12);
  doc.setTextColor(99, 87, 91);
  let headerY = 6;
  const headerLineGap = 6;
  const text1 = "الجمهورية التونسية";
  const text2 = "وزارة التربية";
  let text = "المندوبيّة الجهويّة للتربيّة";
  width = doc.getTextWidth(text1);
  width1 = doc.getTextWidth(text2);
  width2 = doc.getTextWidth(text2);

  doc.text(text1, pdf_width - width - 4, headerY, { align: "center" });
  headerY += headerLineGap;
  doc.text(text2, pdf_width - width1 - 13, headerY, { align: "center" });
  headerY += headerLineGap;
  doc.text(text, pdf_width - width2 - 15, headerY, { align: "center" });
  text = schoolDetails.delegeRegional
    ? "بـ" + schoolDetails.delegeRegional
    : ".....................................بـ";
  width = doc.getTextWidth(text);
  headerY += headerLineGap;
  doc.text(text, pdf_width - width2 - 15, headerY, { align: "center" });

  doc.setFont("PT Bold Heading", "Bold");
  doc.setTextColor(67, 62, 63);
  doc.setFontSize(12);
  let schoolTypeText =
    selectedLevel <= 9 ? " المدرسة الابتدائيّة " : "  المعهد الخاص ";
  doc.text(schoolTypeText, 20, 10);
  text = schoolDetails.ArabicName;
  doc.text(text, 20, 16);

  doc.setFont("Amiri", "normal");
  text = ` ${schoolarYearName}  : السنة الدراسيّة`;
  width = doc.getTextWidth(text);
  doc.setFontSize(13);
  doc.text(text, 10, 23); // this for schoolarYearName

  // ################
  //? Header End here
  // ################

  // ending header line
  doc.setDrawColor(...bulletinColors.color5);
  doc.setLineWidth(0.8);
  doc.line(0, 40 - 12, pdf_width, 40 - 12);

  // trimester rounded rect title
  doc.setDrawColor(...bulletinColors.color5);
  doc.setLineWidth(0.4);
  doc.setFillColor(255, 255, 255);
  doc.roundedRect(pdf_width / 2 - 25, 34 - 12, 50, 12, 4, 4, "FD");

  // trimester text
  doc.setFontSize(16);
  doc.setFont("Amiri", "normal");
  doc.setTextColor(...bulletinColors.color5);
  let title = "";
  switch (selectedTrimester) {
    case "1":
      title = "الثلاثي الأول";
      break;
    case "2":
      title = "الثلاثي الثاني";
      break;
    case "3":
      title = "الثلاثي الثالث";
      break;
  }
  doc.setFont("Noto Sans Arabic", "Bold");
  width = doc.getTextWidth(title);
  doc.text(title, pdf_width / 2 - width / 2, 41.2 - 12);

  //if (studentPhoto) {
  const className = bulletinData.classroomName;
  let xCoord = pdf_width - 10;
  function renderText(
    doc,
    text,
    x,
    y,
    font = "normal",
    fontSize = 12,
    color = [99, 87, 91]
  ) {
    doc.setFont("Amiri", font);
    doc.setFontSize(fontSize);
    doc.setTextColor(...color);
    const width = doc.getTextWidth(text);
    doc.text(text, x - width, y);
    return width;
  }

  const yCoord = 38;
  let gap = 8;
  let studentLabel = student.gender === "male" ? " : التلميذ" : " : التلميذة";
  let studentLabelWidth = renderText(doc, studentLabel, xCoord, yCoord);
  xCoord -= studentLabelWidth;
  let fullNameWidth = renderText(
    doc,
    student.fullName,
    xCoord,
    yCoord,
    "Bold",
    13
  );
  xCoord -= fullNameWidth + gap;
  let classLabelWidth = renderText(doc, " : القسم", xCoord, yCoord);
  xCoord -= classLabelWidth;
  let classWidth = renderText(doc, className, xCoord, yCoord);
  xCoord -= classWidth + gap;
  let nameThreshold = 50;
  let nameLength = student.fullName.length;

  if (nameLength > nameThreshold) {
    gap = 10;
  }

  if (affichageBulletin.identUnique && student.uniqueId) {
    let uniqueIdLabelWidth = renderText(
      doc,
      " : المعرف الوحيد",
      xCoord,
      yCoord
    );
    xCoord -= uniqueIdLabelWidth;
    let uniqueIdWidth = renderText(
      doc,
      String(student.uniqueId),
      xCoord,
      yCoord
    );
    xCoord -= uniqueIdWidth + gap;
  } else {
    xCoord -= gap;
  }

  if (affichageBulletin.numberStudentInsc) {
    let numStudentsLabelWidth = renderText(
      doc,
      " : عدد التلاميذ المرسّمين",
      xCoord,
      yCoord
    );
    xCoord -= numStudentsLabelWidth;
    let numberOfStudents = String(
      Array.isArray(bulletinData.students)
        ? bulletinData.students.length
        : bulletinData.students
    );
    let numStudentsWidth = renderText(doc, numberOfStudents, xCoord, yCoord);
    xCoord -= numStudentsWidth + gap;
  }

  // Add birthdate
  let birthdateOnNewLine = false;
  if (student.BirthDate) {
    const birthDateLabel = " : تاريخ الولادة";
    const birthDate = moment(student.BirthDate).format("DD/MM/YYYY");

    // Pre-calculate required width
    const requiredWidth =
      doc.getTextWidth(birthDateLabel) + doc.getTextWidth(birthDate) + gap;

    // Only move to new line if not enough space
    const currentX = xCoord;
    birthdateOnNewLine = currentX < requiredWidth;
    if (birthdateOnNewLine) {
      xCoord = pdf_width - 10;
      const birthDateY = yCoord + 8;
      let birthDateLabelWidth = renderText(
        doc,
        birthDateLabel,
        xCoord,
        birthDateY
      );
      xCoord -= birthDateLabelWidth;
      let birthDateWidth = renderText(doc, birthDate, xCoord, birthDateY);
    } else {
      // Stay on same line
      let birthDateLabelWidth = renderText(
        doc,
        birthDateLabel,
        currentX,
        yCoord
      );
      xCoord = currentX - birthDateLabelWidth;
      let birthDateWidth = renderText(doc, birthDate, xCoord, yCoord);
      xCoord -= birthDateWidth + gap;
    }
  }

  const cellToDelete = {};
  let topMargin = birthdateOnNewLine ? -12 : -21;
  let tableSize = 9;
  switch (selectedLevel) {
    case 1:
      tableSize = 11.5;
      break;
    case 2:
      tableSize = 11.5;
      break;
    case 3:
      tableSize = 10.5;
      break;
    case 4:
      tableSize = 9.5;
      break;
    default:
      break;
  }

  let totatPts = student.modules.length * 2;
  student.modules.forEach((m) => {
    m.subjects.forEach((c) => {
      if (c.subSubjectMarks) totatPts += c.subSubjectMarks.length * 0.75;
      else totatPts++;
    });
  });
  if (totatPts > totalPtsTreshold) tableSize *= 0.9;
  let longNameSubject = false;
  student.modules.forEach((m) => {
    //try {
    let arr = [];
    let array = [];

    drawModuleHeader(
      doc,
      ctx,
      topMargin,
      m.name,
      affichageBulletin.hideSubject && affichageBulletin.hideSubject === true
    );
    topMargin += 17.2;

    m.subjects.forEach((c) => {
      let subFormattedName = c.name;
      if (c.name.length > 20 && c.name.indexOf(" و ") != -1) {
        let indexOfFirst = c.name.indexOf(" و ");
        subFormattedName =
          c.name.slice(0, indexOfFirst) + "\n" + c.name.slice(indexOfFirst);
      }
      if (c.length > 22) longNameSubject = true;

      const spanNumber = c.subSubjectMarks ? c.subSubjectMarks.length : 1;
      const colspan =
        spanNumber == 1
          ? c.subSubjectMarks && c.subSubjectMarks.length > 0
            ? 1
            : 3
          : 1;
      //ﺍﻟﻤﺎﺩﺓ
      //const lines = subFormattedName.split("\n");
      // Get the maximum length
      //const maxLength = Math.max(...lines.map((line) => line.length));
      const lines = subFormattedName.split("\n");
      //Get the maximum length
      const maxLength = Math.max(...lines.map((line) => line.length));
      let subjectFontSize = maxLength >= 12 ? tableSize * 0.8 : tableSize + 1;
      if (
        !affichageBulletin.hideSubject ||
        affichageBulletin.hideSubject === false ||
        (affichageBulletin.hideSubject &&
          affichageBulletin.hideSubject === true &&
          (!c.subSubjectMarks || c.subSubjectMarks.length == 0))
      )
        arr[8] = {
          content: subFormattedName,
          rowSpan: spanNumber,
          colSpan: colspan,
          styles: {
            halign: "right",
            valign: "middle",
            fillColor: rgbToHex(...bulletinColors.color7),
            fontSize: subjectFontSize,
            textColor: "#ffffff",
            lineColor: [229, 229, 229],
            cellPadding: {
              top: 0.8,
              bottom: 0.8,
              right: 2,
              left: 1,
            },
          },
        };
      // ﺍﻟﻌﺪﺩ / 20
      if (
        !affichageBulletin.hideSubject ||
        affichageBulletin.hideSubject === false ||
        !c.subSubjectMarks ||
        c.subSubjectMarks.length == 0
      )
        arr[5] = {
          content: c.mark ?? "--",
          rowSpan: spanNumber,
          styles: {
            halign: "center",
            valign: "middle",
            fontSize: totatPts > totalPtsTreshold ? 9 : 10,
            lineColor: [...bulletinColors.color5],
            cellPadding: 0,
          },
        };

      arr[4] = cellToDelete;
      arr[3] = cellToDelete;
      arr[2] = cellToDelete;

      //best mark
      arr[1] = {
        content: c.max,
        rowSpan: spanNumber,
        styles: {
          halign: "center",
          valign: "middle",
          fontSize: totatPts > totalPtsTreshold ? 8 : 9,
          cellPadding: 0,
          lineColor: [...bulletinColors.color5],
        },
      };

      //lowest mark
      arr[0] = {
        content: c.min,
        rowSpan: spanNumber,
        styles: {
          halign: "center",
          valign: "middle",
          fontSize: 9,
          cellPadding: 0,
          lineColor: [...bulletinColors.color5],
        },
      };
      //sub subjects
      if (c.subSubjectMarks)
        c.subSubjectMarks.forEach((subs) => {
          try {
            arr[6] = {
              content: subs.mark,
              styles: {
                halign: "center",
                valign: "middle",
                cellPadding: 0,
                fontSize:
                  affichageBulletin.hideSubject &&
                  affichageBulletin.hideSubject === true
                    ? totatPts > totalPtsTreshold
                      ? 9
                      : 10
                    : totatPts > totalPtsTreshold
                    ? 7
                    : 8,
                fillColor:
                  affichageBulletin.hideSubject &&
                  affichageBulletin.hideSubject === true
                    ? "#ffffff"
                    : rgbToHex(...bulletinColors.color9),
                //textColor:
                //  affichageBulletin.hideSubject &&
                //  affichageBulletin.hideSubject === true
                //    ? "#000000"
                //    : rgbToHex(...bulletinColors.color2),
                lineColor:
                  affichageBulletin.hideSubject &&
                  affichageBulletin.hideSubject === true
                    ? [...bulletinColors.color5]
                    : bulletinColors.color2,
              },
            }; //[229, 229, 229]
            const arabicLetters = /[\u0600-\u06FF]/;
            const arabicName = arabicLetters.test(subs.name);
            let ssName = arabicName
              ? "المحفوظات".length <= subs.name.length
                ? //"(/" + subs.name + ")" + subs.maxMark
                  subs.name + "\n" + "(/" + subs.maxMark + ")"
                : "(/" + subs.maxMark + ")  " + subs.name
              : subs.name + "(/" + subs.maxMark + ")";
            arr[7] = {
              content: ssName,
              colSpan:
                affichageBulletin.hideSubject &&
                affichageBulletin.hideSubject === true
                  ? 3
                  : 1,
              styles: {
                halign: "right",
                valign: "middle",
                cellPadding: 0.5,
                fontSize:
                  affichageBulletin.hideSubject &&
                  affichageBulletin.hideSubject === true
                    ? ssName.length > 20
                      ? tableSize * 0.8
                      : tableSize
                    : totatPts > totalPtsTreshold
                    ? 6
                    : 7,
                fillColor:
                  affichageBulletin.hideSubject &&
                  affichageBulletin.hideSubject === true
                    ? rgbToHex(...bulletinColors.color7)
                    : rgbToHex(...bulletinColors.color9),
                textColor:
                  affichageBulletin.hideSubject &&
                  affichageBulletin.hideSubject === true
                    ? "#ffffff"
                    : rgbToHex(...bulletinColors.color2),
                lineColor:
                  affichageBulletin.hideSubject &&
                  affichageBulletin.hideSubject === true
                    ? [229, 229, 229]
                    : bulletinColors.color2,
              },
            };
            array.push(arr);
            arr = [];
          } catch (error) {
            console.error("error sub", error);
          }
        });
      else array.push(arr);
      arr = [];
    });

    //end shifting

    array[0][2] = {
      content: "",
      rowSpan: array.length,
      styles: {
        lineColor: [...bulletinColors.color5],
      },
    };
    //appreciation

    if (
      !m.submodules ||
      m.submodules.every((submodule) => submodule.NbSubjects === 0)
    ) {
      array[0][3] = {
        content: m.appreciation.trim(),
        rowSpan: array.length,
        styles: {
          halign: "center",
          valign: "middle",
          fontSize: m.appreciation.length > 15 ? 10 : 12,
          lineColor: [...bulletinColors.color5],
          cellPadding: 1,
        },
      };
    } else {
      let appIndex = 0;
      m.submodules.forEach((subModule) => {
        const subSubjectCount = m.subjects
          .filter((subject) => subject.submoduleID === subModule._id)
          .reduce(
            (acc, subject) =>
              acc +
              (subject.subSubjectMarks ? subject.subSubjectMarks.length : 1),
            0
          );
        let rowNumber = subSubjectCount;
        array[appIndex][3] = {
          content: subModule.appreciation.trim(),
          rowSpan: rowNumber,
          styles: {
            halign: "center",
            valign: "middle",
            fontSize: 10,
            lineColor: [52, 172, 215],
            cellPadding: 1,
          },
        };
        appIndex += rowNumber;
      });
    }
    //ﻣﻌﺪﻝ ﺍﻟﻤﺠﺎﻝ
    array[0][4] = {
      content: m.moyenne,
      rowSpan: array.length,
      styles: {
        halign: "center",
        fontSize: totatPts > totalPtsTreshold ? 11 : 12,
        valign: "middle",
        lineColor: [...bulletinColors.color5],
      },
    };

    for (let row = 0; row < array.length; row++) {
      array[row] = array[row].filter((cell) => cell !== cellToDelete);
    }
    const bodyStyles =
      totatPts > totalPtsTreshold
        ? {
            minCellHeight: 5,
            cellPadding: { vertical: 1, horizontal: 1 },
          }
        : {};
    doc.autoTable({
      startY: 64 + topMargin,
      theme: "grid",
      styles: {
        font: "Amiri",
        halign: "center",
        fontStyle: "Bold",
      },
      bodyStyles: bodyStyles,
      cellPadding: 0,
      body: array,
      margin: { left: 65, bottom: 0 },
      didDrawPage: function (data) {
        // Reseting top margin. The change will be reflected only after print the first page.
        data.settings.margin.top = 10;
        bulletinfOnTwoPages = doc.internal.getNumberOfPages() > studentCount;
      },
      columnStyles: {
        0: { cellWidth: 10 },
        1: { cellWidth: 10 },
        2: { cellWidth: 1 },
        3: { cellWidth: 38 },
        4: { cellWidth: 15 },
        5: { cellWidth: 16 },
        6: { cellWidth: 8 },
        7: { cellWidth: 18 },
        8: { cellWidth: longNameSubject ? 25 : 23 },
      },
    });

    topMargin = doc.lastAutoTable.finalY - 61.4 + 2;
    /*} catch (error) {
        console.error("error.stack");

        console.trace(error);
      }*/
  });
  drawTrimesterMoyenneAndCards(
    doc,
    ctx,
    birthdateOnNewLine ? -18 : -27,
    student.moyenne,
    bulletinData.maxMoyenne,
    bulletinData.minMoyenne,
    student.pedaMoyenne,
    student.personalizedMoyenne,
    affichageBulletin,
    stampImage,
    config
  );
};

//Coupole EDWEB-1155
const drawBulletinCoupole = async function (
  doc,
  ctx,
  student,
  bulletinData,
  affichageBulletin,
  stampImage,
  config
) {
  const {
    selectedLevel,
    schoolDetails,
    selectedClassName,
    schoolarYearName,
    selectedTrimester,
    studentsData,
  } = config;
  bulletinColors = {
    color1: [70, 187, 239],
    color2: [64, 187, 239],
    color3: [16, 169, 229],
    color4: [67, 62, 63],
    color5: [52, 172, 215],
    color6: [224, 237, 243],
    color7: [64, 187, 239],
    color8: [225, 225, 226],
    color9: [224, 237, 243],
  };
  const record = student;

  const pdf_width = doc.internal.pageSize.width;
  let width = 0;
  let width2 = 0;
  let title = "";
  switch (selectedTrimester) {
    case "1":
      title = "بطاقة أعداد الثلاثي الأول";
      break;
    case "2":
      title = "بطاقة أعداد الثلاثي الثاني";
      break;
    case "3":
      title = "بطاقة أعداد الثلاثي الثالث";
      break;
  }
  // ending header line
  doc.setDrawColor(...bulletinColors.color5);
  doc.setLineWidth(0.8);
  doc.line(0, 40 - 12, pdf_width, 40 - 12);

  // trimester rounded rect title
  doc.setDrawColor(...bulletinColors.color5);
  doc.setLineWidth(0.4);
  doc.setFillColor(255, 255, 255);
  const padding = 5; // Add padding to the text width for the rectangle
  const rectWidth = 70 + padding;

  doc.roundedRect(
    pdf_width / 2 - rectWidth / 2,
    34 - 12,
    rectWidth,
    12,
    4,
    4,
    "FD"
  );
  // trimester text
  doc.setFontSize(15);
  doc.setFont("Amiri", "normal");
  doc.setTextColor(...bulletinColors.color5);

  doc.setFont("Noto Sans Arabic", "Bold");
  width = doc.getTextWidth(title);
  doc.text(title, pdf_width / 2 - width / 2, 41.2 - 12);

  const className = selectedClassName;

  let studentLabel = record.gender === "male" ? " : التلميذ" : " : التلميذة";

  // student name
  doc.setFont("Amiri", "normal");
  doc.setFontSize(12);

  doc.setTextColor(0, 0, 0);
  // Header texts
  doc.setFont("PT Bold Heading", "Bold");
  doc.setFontSize(9.5);
  let text1 = "الجمهورية التونسية";
  let text2 = "وزارة التربية";
  let text3 = " المندوبيّة الجهويّة للتربيّة";
  text3 += schoolDetails.delegeRegional
    ? " بـ" + schoolDetails.delegeRegional
    : "..................بـ";

  doc.setTextColor(99, 87, 91);
  width = doc.getTextWidth(text1);
  doc.text(text1, pdf_width - width - 22, 10);
  width = doc.getTextWidth(text2);
  doc.text(text2, pdf_width - width - 26, 14);
  width = doc.getTextWidth(text3);
  doc.text(text3, pdf_width - width - 12, 18);

  let schoolTypeText =
    selectedLevel <= 9 ? " المدرسة الابتدائيّة " : "  المعهد الخاص ";
  text = schoolDetails.ArabicName
    ? schoolTypeText + schoolDetails.ArabicName
    : "................................................... : المدرسة الابتدائيّة";
  text = ` ${schoolDetails.ArabicName} ${schoolTypeText}`;
  doc.setFont("Amiri", "Bold");
  doc.setFontSize(12);
  width = doc.getTextWidth(text);
  doc.text(text, pdf_width - width - 12, 23);
  doc.setFontSize(11);
  doc.setFont("Amiri", "normal");
  text = ` ${schoolarYearName}  : السنة الدراسيّة`;
  width = doc.getTextWidth(text);
  doc.text(text, 10, 24); // this for schoolarYearName

  // student classroom
  const yCoord = 38.5;
  doc.setTextColor(0, 0, 0);

  let text = studentLabel;
  width = doc.getTextWidth(text);
  doc.text(text, pdf_width - width - 12, yCoord);

  text = record.fullName;
  doc.setFont("Amiri", "normal");
  width2 = doc.getTextWidth(text);
  doc.text(text, pdf_width - width - width2 - 12, yCoord);
  doc.setFont("Amiri", "normal");

  text = " : القسم";
  const textWidth = doc.getTextWidth(text);
  const classNameWidth = doc.getTextWidth(className);

  const totalWidth = textWidth + classNameWidth;
  const centerX = pdf_width / 2;

  doc.text(text, centerX - totalWidth / 2 + classNameWidth, yCoord);
  doc.text(className, centerX - totalWidth / 2, yCoord);
  // number of students in classroom
  if (affichageBulletin.numberStudentInsc) {
    // this for numberStudentInsc
    text = String(
      Array.isArray(bulletinData.students)
        ? bulletinData.students.length
        : bulletinData.students
    );
    width2 = doc.getTextWidth(text);
    doc.text(text, 10, yCoord); // this for numberStudentInsc
    text = " : عدد التلاميذ المرسّمين";
    width = doc.getTextWidth(text);
    doc.text(text, 10 + width2, yCoord); // this for numberStudentInsc
  }

  //let showSubModule;
  //if (affichageBulletin.showSubModule)
  //  showSubModule = affichageBulletin.showSubModule;
  const cellToDelete = {};
  let topMargin = -21;
  let tableSize = 9;
  switch (selectedLevel) {
    case 1:
      tableSize = 11.5;
      break;
    case 2:
      tableSize = 11.5;
      break;
    case 3:
      tableSize = 10.5;
      break;
    case 4:
      tableSize = 9.5;
      break;
    default:
      break;
  }

  let totatPts = student.modules.length * 2;
  student.modules.forEach((m) => {
    m.subjects.forEach((c) => {
      if (c.subSubjectMarks) totatPts += c.subSubjectMarks.length * 0.75;
      else totatPts++;
    });
  });
  if (totatPts > totalPtsTreshold) tableSize *= 0.9;
  let longNameSubject = false;
  student.modules.forEach((m) => {
    //try {
    let arr = [];
    let array = [];

    drawModuleHeader(
      doc,
      ctx,
      topMargin,
      m.name,
      affichageBulletin.hideSubject && affichageBulletin.hideSubject === true
    );
    topMargin += 17.2;

    m.subjects.forEach((c) => {
      let subFormattedName = c.name;
      if (c.name.length > 20 && c.name.indexOf(" و ") != -1) {
        let indexOfFirst = c.name.indexOf(" و ");
        subFormattedName =
          c.name.slice(0, indexOfFirst) + "\n" + c.name.slice(indexOfFirst);
      }
      if (c.length > 25) longNameSubject = true;

      const spanNumber = c.subSubjectMarks ? c.subSubjectMarks.length : 1;
      const colspan =
        spanNumber == 1
          ? c.subSubjectMarks && c.subSubjectMarks.length > 0
            ? 1
            : 3
          : 1;
      //ﺍﻟﻤﺎﺩﺓ
      const lines = subFormattedName.split("\n");
      // Get the maximum length
      const maxLength = Math.max(...lines.map((line) => line.length));
      let subjectFontSize = maxLength >= 23 ? tableSize : tableSize + 1;
      if (
        !affichageBulletin.hideSubject ||
        affichageBulletin.hideSubject === false ||
        (affichageBulletin.hideSubject &&
          affichageBulletin.hideSubject === true &&
          (!c.subSubjectMarks || c.subSubjectMarks.length == 0))
      )
        arr[8] = {
          content: subFormattedName,
          rowSpan: spanNumber,
          colSpan: colspan,
          styles: {
            halign: "right",
            valign: "middle",
            fillColor: rgbToHex(...bulletinColors.color7),
            fontSize: subjectFontSize,
            textColor: "#ffffff",
            lineColor: [229, 229, 229],
            cellPadding: {
              top: 0.8,
              bottom: 0.8,
              right: 2,
              left: 1,
            },
          },
        };
      // ﺍﻟﻌﺪﺩ / 20
      if (
        !affichageBulletin.hideSubject ||
        affichageBulletin.hideSubject === false ||
        !c.subSubjectMarks ||
        c.subSubjectMarks.length == 0
      )
        arr[5] = {
          content: c.mark ?? "--",
          rowSpan: spanNumber,
          styles: {
            halign: "center",
            valign: "middle",
            fontSize: totatPts > totalPtsTreshold ? 9 : 10,
            lineColor: [...bulletinColors.color5],
            cellPadding: 0,
          },
        };

      arr[4] = cellToDelete;
      arr[3] = cellToDelete;
      arr[2] = cellToDelete;

      //best mark
      arr[1] = {
        content: c.max,
        rowSpan: spanNumber,
        styles: {
          halign: "center",
          valign: "middle",
          fontSize: totatPts > totalPtsTreshold ? 8 : 9,
          cellPadding: 0,
          lineColor: [...bulletinColors.color5],
        },
      };

      //lowest mark
      arr[0] = {
        content: c.min,
        rowSpan: spanNumber,
        styles: {
          halign: "center",
          valign: "middle",
          fontSize: 9,
          cellPadding: 0,
          lineColor: [...bulletinColors.color5],
        },
      };
      //sub subjects
      if (c.subSubjectMarks)
        c.subSubjectMarks.forEach((subs) => {
          try {
            arr[6] = {
              content: subs.mark,
              styles: {
                halign: "center",
                valign: "middle",
                cellPadding: 0,
                fontSize:
                  affichageBulletin.hideSubject &&
                  affichageBulletin.hideSubject === true
                    ? totatPts > totalPtsTreshold
                      ? 9
                      : 10
                    : totatPts > totalPtsTreshold
                    ? 7
                    : 8,
                fillColor:
                  affichageBulletin.hideSubject &&
                  affichageBulletin.hideSubject === true
                    ? "#ffffff"
                    : rgbToHex(...bulletinColors.color9),
                //textColor:
                //  affichageBulletin.hideSubject &&
                //  affichageBulletin.hideSubject === true
                //    ? "#000000"
                //    : rgbToHex(...bulletinColors.color2),
                lineColor:
                  affichageBulletin.hideSubject &&
                  affichageBulletin.hideSubject === true
                    ? [...bulletinColors.color5]
                    : bulletinColors.color2,
              },
            }; //[229, 229, 229]
            const arabicLetters = /[\u0600-\u06FF]/;
            const arabicName = arabicLetters.test(subs.name);
            let ssName = arabicName
              ? "المحفوظات".length <= subs.name.length
                ? //"(/" + subs.name + ")" + subs.maxMark
                  subs.name + "\n" + "(/" + subs.maxMark + ")"
                : "(/" + subs.maxMark + ")  " + subs.name
              : subs.name + "(/" + subs.maxMark + ")";
            arr[7] = {
              content: ssName,
              colSpan:
                affichageBulletin.hideSubject &&
                affichageBulletin.hideSubject === true
                  ? 3
                  : 1,
              styles: {
                halign: "right",
                valign: "middle",
                cellPadding: 0.5,
                fontSize:
                  affichageBulletin.hideSubject &&
                  affichageBulletin.hideSubject === true
                    ? ssName.length > 20
                      ? tableSize * 0.8
                      : tableSize
                    : totatPts > totalPtsTreshold
                    ? 6
                    : 7,
                fillColor:
                  affichageBulletin.hideSubject &&
                  affichageBulletin.hideSubject === true
                    ? rgbToHex(...bulletinColors.color7)
                    : rgbToHex(...bulletinColors.color9),
                textColor:
                  affichageBulletin.hideSubject &&
                  affichageBulletin.hideSubject === true
                    ? "#ffffff"
                    : rgbToHex(...bulletinColors.color2),
                lineColor:
                  affichageBulletin.hideSubject &&
                  affichageBulletin.hideSubject === true
                    ? [229, 229, 229]
                    : bulletinColors.color2,
              },
            };
            array.push(arr);
            arr = [];
          } catch (error) {
            console.error("error sub", error);
          }
        });
      else array.push(arr);
      arr = [];
    });

    //end shifting

    array[0][2] = {
      content: "",
      rowSpan: array.length,
      styles: {
        lineColor: [...bulletinColors.color5],
      },
    };
    //appreciation

    if (
      !m.submodules ||
      m.submodules.every((submodule) => submodule.NbSubjects === 0)
    ) {
      array[0][3] = {
        content: m.appreciation.trim(),
        rowSpan: array.length,
        styles: {
          halign: "center",
          valign: "middle",
          cellPadding: 1,
          fontSize: m.appreciation.length > 15 ? 10 : 12,
          lineColor: [...bulletinColors.color5],
        },
      };
    } else {
      let appIndex = 0;
      m.submodules.forEach((subModule) => {
        const subSubjectCount = m.subjects
          .filter((subject) => subject.submoduleID === subModule._id)
          .reduce(
            (acc, subject) =>
              acc +
              (subject.subSubjectMarks ? subject.subSubjectMarks.length : 1),
            0
          );
        let rowNumber = subSubjectCount;
        array[appIndex][3] = {
          content: subModule.appreciation.trim(),
          rowSpan: rowNumber,
          styles: {
            cellPadding: 1,
            halign: "center",
            valign: "middle",
            fontSize: 10,
            lineColor: [52, 172, 215],
          },
        };
        appIndex += rowNumber;
      });
    }
    //ﻣﻌﺪﻝ ﺍﻟﻤﺠﺎﻝ
    array[0][4] = {
      content: m.moyenne,
      rowSpan: array.length,
      styles: {
        halign: "center",
        fontSize: totatPts > totalPtsTreshold ? 11 : 12,
        valign: "middle",
        lineColor: [...bulletinColors.color5],
        textColor: [255, 0, 0], // RGB values for red
      },
    };

    for (let row = 0; row < array.length; row++) {
      array[row] = array[row].filter((cell) => cell !== cellToDelete);
    }
    const bodyStyles =
      totatPts > totalPtsTreshold
        ? {
            minCellHeight: 5,
            cellPadding: { vertical: 1, horizontal: 1 },
          }
        : {};
    doc.autoTable({
      startY: 64 + topMargin,
      theme: "grid",
      styles: {
        font: "Amiri",
        halign: "center",
        fontStyle: "Bold",
      },
      bodyStyles: bodyStyles,
      cellPadding: 0,
      body: array,
      margin: { left: 65, bottom: 0 },
      didDrawPage: function (data) {
        // Reseting top margin. The change will be reflected only after print the first page.
        data.settings.margin.top = 10;
        bulletinfOnTwoPages = doc.internal.getNumberOfPages() > studentCount;
      },
      columnStyles: {
        0: { cellWidth: 10 },
        1: { cellWidth: 10 },
        2: { cellWidth: 1 },
        3: { cellWidth: 38 },
        4: { cellWidth: 15 },
        5: { cellWidth: 16 },
        6: { cellWidth: 8 },
        7: { cellWidth: 18 },
        8: { cellWidth: longNameSubject ? 25 : 23 },
      },
    });

    topMargin = doc.lastAutoTable.finalY - 61.4 + 2;
    /*} catch (error) {
        console.error("error.stack");

        console.trace(error);
      }*/
  });

  drawTrimesterMoyenneAndCards(
    doc,
    ctx,
    -27,
    student.moyenne,
    bulletinData.maxMoyenne,
    bulletinData.minMoyenne,
    student.pedaMoyenne,
    student.personalizedMoyenne,
    affichageBulletin,
    stampImage,
    config,
    "CP" //template id for coupole
  );
};
const drawBulletinArcEnciel = async function (
  doc,
  ctx,
  student,
  bulletinData,
  affichageBulletin,
  stampImage,
  qrImage,
  config
) {
  const {
    selectedLevel,
    schoolDetails,
    selectedClassName,
    schoolarYearName,
    selectedTrimester,
  } = config;

  bulletinColors = {
    color1: [40, 127, 179],
    color2: [35, 127, 179],
    color3: [8, 119, 169],
    color4: [37, 32, 33],
    color5: [32, 132, 165],
    color6: [160, 179, 236],
    color7: [35, 127, 179],
    color8: [255, 255, 255],
    color9: [160, 179, 236],
  };
  const record = student;

  const pdf_width = doc.internal.pageSize.width;
  let width = 0;
  let width2 = 0;

  // ending header line
  doc.setDrawColor(...bulletinColors.color5);
  doc.setLineWidth(0.8);
  doc.line(0, 40 - 12, pdf_width, 40 - 12);

  // trimester rounded rect title
  doc.setDrawColor(...bulletinColors.color5);
  doc.setLineWidth(0.4);
  doc.setFillColor(255, 255, 255);
  doc.roundedRect(pdf_width / 2 - 25, 34 - 12, 50, 12, 4, 4, "FD");

  // trimester text
  doc.setFontSize(16);
  doc.setFont("Amiri", "normal");
  doc.setTextColor(...bulletinColors.color5);
  let title = "";
  switch (selectedTrimester) {
    case "1":
      title = "الثلاثي الأول";
      break;
    case "2":
      title = "الثلاثي الثاني";
      break;
    case "3":
      title = "الثلاثي الثالث";
      break;
  }
  doc.setFont("Noto Sans Arabic", "Bold");
  width = doc.getTextWidth(title);
  doc.text(title, pdf_width / 2 - width / 2, 41.2 - 12);

  const className = selectedClassName;

  let studentLabel = record.gender === "male" ? " : التلميذ" : " : التلميذة";

  // student name
  doc.setFont("Amiri", "normal");
  doc.setFontSize(12);

  doc.setTextColor(0, 0, 0);

  let schoolTypeText =
    selectedLevel <= 9 ? " المدرسة الابتدائيّة " : "  المعهد الخاص ";
  text = schoolDetails.ArabicName
    ? schoolTypeText + schoolDetails.ArabicName
    : "................................................... : المدرسة الابتدائيّة";
  width = doc.getTextWidth(text);
  doc.text(text, pdf_width - width - 12, 12);
  text = ` ${schoolarYearName}  : السنة الدراسيّة`;
  width = doc.getTextWidth(text);
  doc.text(text, 10, 12);

  let text = studentLabel;
  width = doc.getTextWidth(text);
  doc.text(text, pdf_width - width - 12, 17.5);

  text = student.fullName;
  doc.setFont("Amiri", "normal");
  doc.setFontSize(13);
  width2 = doc.getTextWidth(text);
  doc.text(text, pdf_width - width - width2 - 12, 17.5);
  doc.setFont("Amiri", "normal");
  doc.setFontSize(12);
  // student classroom
  text = " : القسم";
  doc.setTextColor(0, 0, 0);
  width = doc.getTextWidth(text);
  width2 = doc.getTextWidth(className);
  doc.text(text, 13 + width2, 17.5);

  doc.text(className, 12, 17.5);
  // number of students in classroom
  if (affichageBulletin.numberStudentInsc) {
    text = String(
      Array.isArray(bulletinData.students)
        ? bulletinData.students.length
        : bulletinData.students
    );
    width2 = doc.getTextWidth(text);
    doc.text(text, 12, 23);
    text = " : عدد التلاميذ المرسّمين";
    width = doc.getTextWidth(text);
    doc.text(text, 12 + width2, 23);
  }

  // identUnique
  if (affichageBulletin.identUnique && student.uniqueId) {
    text = " : المعرف الوحيد";
    width = doc.getTextWidth(text);
    doc.text(text, pdf_width - width - 12, 23);
    text = String(student.uniqueId);
    width2 = doc.getTextWidth(text);
    doc.text(text, pdf_width - width2 - width - 12, 23);
  }
  let showSubModule;
  if (affichageBulletin.showSubModule)
    showSubModule = affichageBulletin.showSubModule;
  const cellToDelete = {};
  let topMargin = -21;
  let tableSize = 9;
  switch (selectedLevel) {
    case 1:
      tableSize = 11.5;
      break;
    case 2:
      tableSize = 11.5;
      break;
    case 3:
      tableSize = 10.5;
      break;
    case 4:
      tableSize = 9.5;
      break;
    default:
      break;
  }

  let totatPts = student.modules.length * 2;
  student.modules.forEach((m) => {
    m.subjects.forEach((c) => {
      if (c.subSubjectMarks) totatPts += c.subSubjectMarks.length * 0.75;
      else totatPts++;
    });
  });
  if (totatPts > totalPtsTreshold) tableSize *= 0.9;
  let longNameSubject = false;
  student.modules.forEach((m) => {
    //try {
    let arr = [];
    let array = [];

    drawModuleHeader(doc, ctx, topMargin, m.name, true);
    topMargin += 17.2;

    m.subjects.forEach((c) => {
      let subFormattedName = c.name;
      if (c.name.length > 20 && c.name.indexOf(" و ") != -1) {
        let indexOfFirst = c.name.indexOf(" و ");
        subFormattedName =
          c.name.slice(0, indexOfFirst) + "\n" + c.name.slice(indexOfFirst + 1);
      }
      if (c.name.length > 25) longNameSubject = true;
      const spanNumber = c.subSubjectMarks ? c.subSubjectMarks.length : 1;
      const colspan =
        spanNumber == 1
          ? c.subSubjectMarks && c.subSubjectMarks.length > 0
            ? 1
            : 3
          : 1;
      //const lines = subFormattedName.split("\n");
      // Get the maximum length
      //const maxLength = Math.max(...lines.map((line) => line.length));
      const lines = subFormattedName.split("\n");
      //Get the maximum length
      const maxLength = Math.max(...lines.map((line) => line.length));
      let subjectFontSize = maxLength >= 12 ? tableSize * 0.8 : tableSize + 1;

      //ﺍﻟﻤﺎﺩﺓ
      if (
        !affichageBulletin.hideSubject ||
        affichageBulletin.hideSubject === false ||
        (affichageBulletin.hideSubject &&
          affichageBulletin.hideSubject === true &&
          (!c.subSubjectMarks || c.subSubjectMarks.length == 0))
      )
        arr[8] = {
          content: subFormattedName,
          rowSpan: spanNumber,
          colSpan: colspan,
          styles: {
            halign: "right",
            valign: "middle",
            fillColor: rgbToHex(...bulletinColors.color7),
            fontSize: subjectFontSize,
            textColor: "#ffffff",
            lineColor: [229, 229, 229],
            cellPadding: {
              top: 0.8,
              bottom: 0.8,
              right: 2,
              left: 1,
            },
          },
        };
      // ﺍﻟﻌﺪﺩ / 20
      if (
        !affichageBulletin.hideSubject ||
        affichageBulletin.hideSubject === false ||
        !c.subSubjectMarks ||
        c.subSubjectMarks.length == 0
      )
        arr[5] = {
          content: c.mark ?? "--",
          rowSpan: spanNumber,
          styles: {
            halign: "center",
            valign: "middle",
            fontSize: totatPts > totalPtsTreshold ? 9 : 10,
            lineColor: [...bulletinColors.color5],
            cellPadding: 0,
          },
        };

      arr[4] = cellToDelete;
      arr[3] = cellToDelete;
      arr[2] = cellToDelete;

      //best mark
      arr[1] = {
        content: c.max,
        rowSpan: spanNumber,
        styles: {
          halign: "center",
          valign: "middle",
          fontSize: totatPts > totalPtsTreshold ? 8 : 9,
          cellPadding: 0,
          lineColor: [...bulletinColors.color5],
        },
      };

      //lowest mark
      arr[0] = {
        content: c.min,
        rowSpan: spanNumber,
        styles: {
          halign: "center",
          valign: "middle",
          fontSize: 9,
          cellPadding: 0,
          lineColor: [...bulletinColors.color5],
        },
      };
      //sub subjects
      if (c.subSubjectMarks)
        c.subSubjectMarks.forEach((subs) => {
          try {
            arr[6] = {
              content: subs.mark,
              styles: {
                halign: "center",
                valign: "middle",
                cellPadding: 0,
                fontSize:
                  affichageBulletin.hideSubject &&
                  affichageBulletin.hideSubject === true
                    ? totatPts > totalPtsTreshold
                      ? 9
                      : 10
                    : totatPts > totalPtsTreshold
                    ? 7
                    : 8,
                fillColor:
                  affichageBulletin.hideSubject &&
                  affichageBulletin.hideSubject === true
                    ? "#ffffff"
                    : rgbToHex(...bulletinColors.color9),
                //textColor:
                //  affichageBulletin.hideSubject &&
                //  affichageBulletin.hideSubject === true
                //    ? "#000000"
                //    : rgbToHex(...bulletinColors.color2),
                lineColor:
                  affichageBulletin.hideSubject &&
                  affichageBulletin.hideSubject === true
                    ? [32, 132, 165]
                    : bulletinColors.color2,
              },
            };

            const arabicLetters = /[\u0600-\u06FF]/;
            const arabicName = arabicLetters.test(subs.name);
            let ssName = arabicName
              ? "المحفوظات".length <= subs.name.length
                ? //"(/" + subs.name + ")" + subs.maxMark
                  subs.name + "\n" + "(/" + subs.maxMark + ")"
                : "(/" + subs.maxMark + ")  " + subs.name
              : subs.name + "(/" + subs.maxMark + ")";
            arr[7] = {
              content: ssName,
              colSpan:
                affichageBulletin.hideSubject &&
                affichageBulletin.hideSubject === true
                  ? 3
                  : 1,
              styles: {
                halign: "right",
                valign: "middle",
                cellPadding: 0.5,
                fontSize:
                  affichageBulletin.hideSubject &&
                  affichageBulletin.hideSubject === true
                    ? ssName > 20
                      ? tableSize * 0.8
                      : tableSize
                    : totatPts > totalPtsTreshold
                    ? 6
                    : 7,
                fillColor:
                  affichageBulletin.hideSubject &&
                  affichageBulletin.hideSubject === true
                    ? rgbToHex(...bulletinColors.color7)
                    : rgbToHex(...bulletinColors.color9),
                textColor:
                  affichageBulletin.hideSubject &&
                  affichageBulletin.hideSubject === true
                    ? "#ffffff"
                    : rgbToHex(...bulletinColors.color2),
                lineColor:
                  affichageBulletin.hideSubject &&
                  affichageBulletin.hideSubject === true
                    ? [229, 229, 229]
                    : bulletinColors.color2,
              },
            };
            array.push(arr);
            arr = [];
          } catch (error) {
            console.error("error sub", error);
          }
        });
      else array.push(arr);
      arr = [];
    });

    //end shifting

    array[0][2] = {
      content: "",
      rowSpan: array.length,
      styles: {
        lineColor: [...bulletinColors.color5],
      },
    };
    //appreciation

    if (
      !m.submodules ||
      m.submodules.every((submodule) => submodule.NbSubjects === 0)
    ) {
      array[0][3] = {
        content: m.appreciation.trim(),
        rowSpan: array.length,
        styles: {
          halign: "center",
          valign: "middle",
          fontSize: m.appreciation.length > 15 ? 10 : 12,
          lineColor: [...bulletinColors.color5],
          cellPadding: 1,
        },
      };
    } else {
      let appIndex = 0;
      m.submodules.forEach((subModule) => {
        const subSubjectCount = m.subjects
          .filter((subject) => subject.submoduleID === subModule._id)
          .reduce(
            (acc, subject) =>
              acc +
              (subject.subSubjectMarks ? subject.subSubjectMarks.length : 1),
            0
          );
        let rowNumber = subSubjectCount;
        array[appIndex][3] = {
          content: subModule.appreciation.trim(),
          rowSpan: rowNumber,
          styles: {
            halign: "center",
            valign: "middle",
            fontSize: 10,
            lineColor: [52, 172, 215],
            cellPadding: 1,
          },
        };
        appIndex += rowNumber;
      });
    }
    //ﻣﻌﺪﻝ ﺍﻟﻤﺠﺎﻝ
    array[0][4] = {
      content: m.moyenne,
      rowSpan: array.length,
      styles: {
        halign: "center",
        fontSize: totatPts > totalPtsTreshold ? 11 : 12,
        valign: "middle",
        lineColor: [...bulletinColors.color5],
      },
    };

    for (let row = 0; row < array.length; row++) {
      array[row] = array[row].filter((cell) => cell !== cellToDelete);
    }
    const bodyStyles =
      totatPts > totalPtsTreshold
        ? {
            minCellHeight: 5,
            cellPadding: { vertical: 1, horizontal: 1 },
          }
        : {};
    doc.autoTable({
      startY: 64 + topMargin,
      theme: "grid",
      styles: {
        font: "Amiri",
        halign: "center",
        fontStyle: "Bold",
      },
      bodyStyles: bodyStyles,
      cellPadding: 0,
      body: array,
      margin: { left: 65, bottom: 0 },
      didDrawPage: function (data) {
        // Reseting top margin. The change will be reflected only after print the first page.
        data.settings.margin.top = 10;
        bulletinfOnTwoPages = doc.internal.getNumberOfPages() > studentCount;
      },
      columnStyles: {
        0: { cellWidth: 10 },
        1: { cellWidth: 10 },
        2: { cellWidth: 1 },
        3: { cellWidth: 38 },
        4: { cellWidth: 15 },
        5: { cellWidth: 16 },
        6: { cellWidth: 8 },
        7: { cellWidth: 18 },
        8: { cellWidth: longNameSubject ? 25 : 23 },
      },
    });

    topMargin = doc.lastAutoTable.finalY - 61.4 + 2;
    /*} catch (error) {
        console.error("error.stack");

        console.trace(error);
      }*/
  });
  drawTrimesterMoyenneAndCardsArcEnciel(
    doc,
    ctx,
    -27,
    student.moyenne,
    bulletinData.maxMoyenne,
    bulletinData.minMoyenne,
    student.pedaMoyenne,
    student.personalizedMoyenne,
    affichageBulletin,
    stampImage,
    qrImage,
    config
  );
};

const drawBulletinMalekEsaghir = async function (
  doc,
  ctx,
  student,
  bulletinData,
  affichageBulletin,
  stampImage,
  config
) {
  const {
    selectedLevel,
    schoolDetails,
    selectedClassName,
    schoolarYearName,
    selectedTrimester,
  } = config;
  console.log("using drawBulletinMalekEsaghir template ");
  //bulletinTemplateColors
  bulletinColors = {
    color1: [235, 171, 94], //color boarder module header
    color2: [242, 154, 48], //color bg ﻣﺠﺎﻝ  ﺍﻟﻤﺎﺩﺓ
    color3: [245, 155, 47],
    color4: [67, 62, 63], //color bg subjucts
    color5: [247, 148, 29], //247, 148, 29
    color6: [240, 227, 204],
    color7: [242, 154, 48], //color bg subjucts
    color8: [247, 247, 250], //color bg cell ﺃﻋﻠﻰ ﻣﻌﺪﻝ ﺑﺎﻟﻘﺴﻢ
    color9: [240, 227, 204], //
  };

  const record = student;
  // Load your image as a data URL or use an existing image URL
  const imageUrl =
    "/resources/images/templatesBulletin/tepmplate_malek_essaghir_modifed.png";

  // Add the image as a background
  const pdf_width = doc.internal.pageSize.width;
  const pdf_height = doc.internal.pageSize.height;

  let width = 0;
  let width2 = 0;
  doc.addImage(imageUrl, "JPEG", 0, 0, pdf_width, pdf_height);
  let yMargin = 8;
  // ending header line
  doc.setDrawColor(...bulletinColors.color5);
  doc.setLineWidth(0.5);
  doc.line(0, 40 - yMargin, pdf_width, 40 - yMargin);

  // trimester rounded rect title
  doc.setDrawColor(...bulletinColors.color5);
  doc.setLineWidth(0.4);
  doc.setFillColor(255, 255, 255);
  doc.roundedRect(pdf_width / 2 - 25, 34 - yMargin, 50, 12, 4, 4, "FD");

  // trimester text
  doc.setFontSize(16);
  doc.setFont("Amiri", "normal");
  doc.setTextColor(...bulletinColors.color5);
  let title = "";
  switch (selectedTrimester) {
    case "1":
      title = "الثلاثي الأول";
      break;
    case "2":
      title = "الثلاثي الثاني";
      break;
    case "3":
      title = "الثلاثي الثالث";
      break;
  }
  doc.setFont("Noto Sans Arabic", "Bold");
  width = doc.getTextWidth(title);
  doc.text(title, pdf_width / 2 - width / 2, 41.2 - yMargin);

  const className = selectedClassName;

  let studentLabel = record.gender === "male" ? " : التلميذ" : " : التلميذة";

  // student name
  doc.setFont("Amiri", "normal");
  doc.setFontSize(12);

  doc.setTextColor(0, 0, 0);

  let schoolTypeText =
    selectedLevel <= 9 ? " المدرسة الابتدائيّة " : "  المعهد الخاص ";
  text = schoolDetails.ArabicName
    ? schoolTypeText + schoolDetails.ArabicName
    : "................................................... : المدرسة الابتدائيّة";
  let widthSc = doc.getTextWidth(text);
  doc.text(text, pdf_width / 2 - widthSc / 2, 12);

  text = ` ${schoolarYearName}  : السنة الدراسيّة`;
  width = doc.getTextWidth(text);
  doc.text(text, 10, 12);
  doc.setFont("Amiri", "normal");

  let text = studentLabel;
  width = doc.getTextWidth(text);
  doc.text(text, pdf_width / 2 + widthSc / 4 - 1, 17.5);

  text = student.fullName;
  doc.setFont("Amiri", "normal");
  doc.setFontSize(13);
  width2 = doc.getTextWidth(text);
  doc.text(text, pdf_width / 2 - width2 + widthSc / 4 - 1, 17.5);
  doc.setFont("Amiri", "normal");
  doc.setFontSize(12);
  // student classroom
  text = " : القسم";
  doc.setTextColor(0, 0, 0);
  width = doc.getTextWidth(text);
  width2 = doc.getTextWidth(className);
  doc.text(text, 13 + width2, 17.5);

  doc.text(className, 12, 17.5);
  // number of students in classroom
  if (affichageBulletin.numberStudentInsc) {
    text = String(
      Array.isArray(bulletinData.students)
        ? bulletinData.students.length
        : bulletinData.students
    );
    width2 = doc.getTextWidth(text);
    doc.text(text, 12, 23);
    text = " : عدد التلاميذ المرسّمين";
    width = doc.getTextWidth(text);
    doc.text(text, 12 + width2, 23);
  }

  // identUnique
  if (affichageBulletin.identUnique && student.uniqueId) {
    text = String(student.uniqueId) + " : المعرف الوحيد";
    width = doc.getTextWidth(text);
    doc.text(text, pdf_width / 2 - width / 2, 23);
  }
  const cellToDelete = {};
  let topMargin = -21;
  let tableSize = 9;
  switch (selectedLevel) {
    case 1:
      tableSize = 11.5;
      break;
    case 2:
      tableSize = 11.5;
      break;
    case 3:
      tableSize = 10.5;
      break;
    case 4:
      tableSize = 9.5;
      break;
    default:
      break;
  }
  let totatPts = student.modules.length * 2;
  student.modules.forEach((m) => {
    m.subjects.forEach((c) => {
      if (c.subSubjectMarks) totatPts += c.subSubjectMarks.length * 0.75;
      else totatPts++;
    });
  });
  if (totatPts > totalPtsTreshold) tableSize *= 0.9;
  let longNameSubject = false;
  student.modules.forEach((m) => {
    //try {
    let arr = [];
    let array = [];

    drawModuleHeader(doc, ctx, topMargin, m.name);
    topMargin += 17.2;

    m.subjects.forEach((c) => {
      let subFormattedName = c.name;
      if (c.name.length > 20 && c.name.indexOf(" و ") != -1) {
        let indexOfFirst = c.name.indexOf(" و ");
        subFormattedName =
          c.name.slice(0, indexOfFirst) + "\n" + c.name.slice(indexOfFirst);
      }
      if (c.length > 25) longNameSubject = true;
      const spanNumber = c.subSubjectMarks ? c.subSubjectMarks.length : 1;
      const colspan =
        spanNumber == 1
          ? c.subSubjectMarks && c.subSubjectMarks.length > 0
            ? 1
            : 3
          : 1;
      //const lines = subFormattedName.split("\n");
      // Get the maximum length
      //const maxLength = Math.max(...lines.map((line) => line.length));
      const lines = subFormattedName.split("\n");
      //Get the maximum length
      const maxLength = Math.max(...lines.map((line) => line.length));
      let subjectFontSize = maxLength >= 12 ? tableSize * 0.8 : tableSize + 1;
      //ﺍﻟﻤﺎﺩﺓ
      arr[8] = {
        content: subFormattedName,
        rowSpan: spanNumber,
        colSpan: colspan,
        styles: {
          halign: "right",
          valign: "middle",

          fillColor: rgbToHex(...bulletinColors.color7),
          fontSize: subjectFontSize,
          textColor: "#ffffff",
          lineColor: [229, 229, 229],
          cellPadding: {
            top: 0.8,
            bottom: 0.8,
            right: 2,
            left: 1,
          },
        },
      };
      // ﺍﻟﻌﺪﺩ / 20
      arr[5] = {
        content: c.mark ?? "--",
        rowSpan: spanNumber,
        styles: {
          halign: "center",
          valign: "middle",
          fontSize: totatPts > totalPtsTreshold ? 9 : 10,
          lineColor: [...bulletinColors.color5],
        },
      };

      arr[4] = cellToDelete;
      arr[3] = cellToDelete;
      arr[2] = cellToDelete;

      //best mark
      arr[1] = {
        content: c.max,
        rowSpan: spanNumber,
        styles: {
          halign: "center",
          valign: "middle",
          fontSize: 9,
          cellPadding: 0,
          lineColor: [...bulletinColors.color5],
        },
      };

      //lowest mark
      arr[0] = {
        content: c.min,
        rowSpan: spanNumber,
        styles: {
          halign: "center",
          valign: "middle",
          fontSize: 9,
          cellPadding: 0,
          lineColor: [...bulletinColors.color5],
        },
      };
      //sub subjects
      if (c.subSubjectMarks)
        c.subSubjectMarks.forEach((subs) => {
          try {
            arr[6] = {
              content: subs.mark ?? "--",
              styles: {
                fontStyle: "Bold",
                halign: "center",
                valign: "middle",
                cellPadding: 0,
                fontSize: totatPts > totalPtsTreshold ? 7 : 8,
                fillColor: rgbToHex(...bulletinColors.color9),
                textColor: rgbToHex(...[0, 0, 0]),
                lineColor: bulletinColors.color2,
              },
            };
            const arabicLetters = /[\u0600-\u06FF]/;
            const arabicName = arabicLetters.test(subs.name);
            let ssName = arabicName
              ? "المحفوظات".length <= subs.name.length
                ? //"(/" + subs.name + ")" + subs.maxMark
                  subs.name + "\n" + "(/" + subs.maxMark + ")"
                : "(/" + subs.maxMark + ")  " + subs.name
              : subs.name + "(/" + subs.maxMark + ")";
            arr[7] = {
              content: ssName,
              styles: {
                halign: "right",
                valign: "middle",
                cellPadding: 0.5,
                fontSize: totatPts > totalPtsTreshold ? 6 : 7,
                fillColor: rgbToHex(...bulletinColors.color9),
                textColor: rgbToHex(...bulletinColors.color2),
                lineColor: bulletinColors.color2,
              },
            };
            array.push(arr);
            arr = [];
          } catch (error) {
            console.error("error sub", error);
          }
        });
      else array.push(arr);
      arr = [];
    });

    //end shifting

    array[0][2] = {
      content: "",
      rowSpan: array.length,
      styles: {
        lineColor: [...bulletinColors.color5],
      },
    };
    //appreciation

    if (
      !m.submodules ||
      m.submodules[0].NbSubjects == 0 ||
      m.submodules[1].NbSubjects == 0
    ) {
      array[0][3] = {
        content: m.appreciation.trim(),
        rowSpan: array.length,
        styles: {
          halign: "center",
          valign: "middle",
          cellPadding: 1,
          fontSize: m.appreciation.length > 15 ? 10 : 12,
          lineColor: [...bulletinColors.color5],
        },
      };
    } else {
      let appIndex = 0;
      m.submodules.forEach((subModule) => {
        const subSubjectCount = m.subjects
          .filter((subject) => subject.submoduleID === subModule._id)
          .reduce(
            (acc, subject) =>
              acc +
              (subject.subSubjectMarks ? subject.subSubjectMarks.length : 1),
            0
          );
        let rowNumber = subSubjectCount;
        array[appIndex][3] = {
          content: subModule.appreciation.trim(),
          rowSpan: rowNumber,
          styles: {
            halign: "center",
            valign: "middle",
            fontSize: 10,
            lineColor: [...bulletinColors.color5],
            cellPadding: 1,
          },
        };
        appIndex += rowNumber;
      });
      ////////////////////////
      //array[0][3] = {
      //  content: m.submodules[0].appreciation.trim(),
      //  rowSpan: m.submodules[0].NbSubjects,
      //  styles: {
      //    halign: "center",
      //    valign: "top",
      //    fontSize: 10,
      //    lineColor: [52, 172, 215],
      //  },
      //};
      //array[m.submodules[0].NbSubjects][3] = {
      //  content: m.submodules[1].appreciation.trim(),
      //  rowSpan: m.submodules[1].NbSubjects,
      //  styles: {
      //    halign: "center",
      //    valign: "top",
      //    fontSize: 10,
      //    lineColor: [52, 172, 215],
      //  },
      //};
    }
    //ﻣﻌﺪﻝ ﺍﻟﻤﺠﺎﻝ
    array[0][4] = {
      content: m.moyenne,
      rowSpan: array.length,
      styles: {
        halign: "center",
        fontSize: 12,
        valign: "middle",
        lineColor: [...bulletinColors.color5],
      },
    };

    for (let row = 0; row < array.length; row++) {
      array[row] = array[row].filter((cell) => cell !== cellToDelete);
    }
    const bodyStyles =
      totatPts > totalPtsTreshold
        ? {
            minCellHeight: 5,
            cellPadding: { vertical: 1, horizontal: 1 },
          }
        : {};
    doc.autoTable({
      startY: 64 + topMargin,
      theme: "grid",
      styles: {
        font: "Amiri",
        halign: "center",
        fontStyle: "Bold",
      },
      bodyStyles: bodyStyles,
      cellPadding: 0,
      body: array,
      margin: { left: 65, bottom: 0 },
      didDrawPage: function (data) {
        // Reseting top margin. The change will be reflected only after print the first page.
        data.settings.margin.top = 10;
        //if (allStudentOption && !allStudentOptionFirst) studentCounter++;
        //studentCounter++;
        bulletinfOnTwoPages = doc.internal.getNumberOfPages() > studentCount;
      },
      columnStyles: {
        0: { cellWidth: 10 },
        1: { cellWidth: 10 },
        2: { cellWidth: 1 },
        3: { cellWidth: 38 },
        4: { cellWidth: 15 },
        5: { cellWidth: 16 },
        6: { cellWidth: 8 },
        7: { cellWidth: 18 },
        8: { cellWidth: longNameSubject ? 25 : 23 },
      },
    });

    topMargin = doc.lastAutoTable.finalY - 61.4 + 2;
    /*} catch (error) {
        console.error("error.stack");

        console.trace(error);
      }*/
  });

  drawTrimesterMoyenneAndCards(
    doc,
    ctx,
    -27,
    student.moyenne,
    bulletinData.maxMoyenne,
    bulletinData.minMoyenne,
    student.pedaMoyenne,
    student.personalizedMoyenne,
    affichageBulletin,
    stampImage,
    config
  );
};

const drawBulletinMondial = async function (
  doc,
  ctx,
  student,
  bulletinData,
  affichageBulletin,
  stampImage,
  config
) {
  const {
    selectedLevel,
    schoolDetails,
    selectedClassName,
    schoolarYearName,
    certifs,
    selectedTrimester,
  } = config;
  bulletinColors = {
    color1: [70, 187, 239],
    color2: [64, 187, 239],
    color3: [16, 169, 229],
    color4: [67, 62, 63],
    color5: [52, 172, 215],
    color6: [224, 237, 243],
    color7: [64, 187, 239],
    color8: [225, 225, 226],
    color9: [224, 237, 243],
  };
  const record = student;

  const pdf_width = doc.internal.pageSize.width;
  let width = 0;
  let width2 = 0;

  // ending header line
  doc.setDrawColor(...bulletinColors.color5);
  doc.setLineWidth(0.8);
  doc.line(0, 40 - 18, pdf_width, 40 - 18);

  // trimester rounded rect title
  doc.setDrawColor(...bulletinColors.color5);
  doc.setLineWidth(0.4);
  doc.setFillColor(255, 255, 255);
  doc.roundedRect(pdf_width / 2 - 25, 34 - 18, 48, 12, 4, 4, "FD");

  // trimester text
  doc.setFontSize(16);
  doc.setFont("Amiri", "normal");
  doc.setTextColor(...bulletinColors.color5);
  let title = "";
  switch (selectedTrimester) {
    case "1":
      title = "الثلاثي الأول";
      break;
    case "2":
      title = "الثلاثي الثاني";
      break;
    case "3":
      title = "الثلاثي الثالث";
      break;
  }
  doc.setFont("Noto Sans Arabic", "Bold");
  width = doc.getTextWidth(title);
  doc.text(title, pdf_width / 2 - width / 2, 41.2 - 18);

  const className = selectedClassName;

  let studentLabel = record.gender === "male" ? " : التلميذ" : " : التلميذة";

  // student name
  doc.setFont("Amiri", "normal");
  doc.setFontSize(12);

  doc.setTextColor(0, 0, 0);

  let schoolTypeText =
    selectedLevel <= 9 ? " المدرسة الابتدائيّة " : "  المعهد الخاص ";
  text = schoolDetails.ArabicName
    ? schoolTypeText + schoolDetails.ArabicName
    : "................................................... : المدرسة الابتدائيّة";
  width = doc.getTextWidth(text);
  doc.text(text, pdf_width - width - 12, 8);
  text = ` ${schoolarYearName}  : السنة الدراسيّة`;
  width = doc.getTextWidth(text);
  doc.text(text, 10, 8);

  let text = studentLabel;
  width = doc.getTextWidth(text);
  doc.text(text, pdf_width - width - 12, 14.5);

  text = student.fullName;
  doc.setFont("Amiri", "normal");
  doc.setFontSize(13);
  width2 = doc.getTextWidth(text);
  doc.text(text, pdf_width - width - width2 - 12, 14.5);
  doc.setFont("Amiri", "normal");
  doc.setFontSize(12);
  // student classroom
  text = " : القسم";
  doc.setTextColor(0, 0, 0);
  width = doc.getTextWidth(text);
  width2 = doc.getTextWidth(className);
  doc.text(text, 13 + width2, 14.5);

  doc.text(className, 12, 14.5);
  // number of students in classroom
  if (affichageBulletin.numberStudentInsc) {
    text = String(
      Array.isArray(bulletinData.students)
        ? bulletinData.students.length
        : bulletinData.students
    );
    width2 = doc.getTextWidth(text);
    doc.text(text, 12, 20);
    text = " : عدد التلاميذ المرسّمين";
    width = doc.getTextWidth(text);
    doc.text(text, 12 + width2, 20);
  }

  // identUnique
  if (affichageBulletin.identUnique && student.uniqueId) {
    text = " : المعرف الوحيد";
    width = doc.getTextWidth(text);
    doc.text(text, pdf_width - width - 12, 20);
    text = String(student.uniqueId);
    width2 = doc.getTextWidth(text);
    doc.text(text, pdf_width - width2 - width - 12, 20);
  }
  const cellToDelete = {};
  let topMargin = -41;
  let tableSize = 9;
  switch (selectedLevel) {
    case 1:
      tableSize = 11.5;
      break;
    case 2:
      tableSize = 11.5;
      break;
    case 3:
      tableSize = 10.5;
      break;
    case 4:
      tableSize = 9.5;
      break;
    default:
      break;
  }

  student.modules.forEach((m) => {
    //try {
    let arr = [];
    let array = [];

    drawModuleHeaderMondial(doc, ctx, topMargin, m.name);
    topMargin += 17.2;
    let formattedModuName = m.name;
    if (m.name.length > 23 && m.name.indexOf(" و ") != -1) {
      let indexOfFirst = m.name.indexOf(" و ");
      formattedModuName =
        m.name.slice(0, indexOfFirst) + "\n" + m.name.slice(indexOfFirst);
    }
    let moduleSpan = 0;
    m.subjects.forEach((c) => {
      moduleSpan +=
        c.subSubjectMarks && c.subSubjectMarks.length > 0
          ? c.subSubjectMarks.length
          : 1;
    });
    // Module Area
    arr[9] = {
      content: formattedModuName,
      rowSpan: moduleSpan,
      colSpan: 1,
      styles: {
        halign: "right",
        valign: "middle",
        cellPadding: {
          right: 1,
          left: 1,
        },
        fillColor: rgbToHex(...bulletinColors.color7),
        fontSize: 10,
        textColor: "#ffffff",
        lineColor: [229, 229, 229],
      },
    };
    m.subjects.forEach((c) => {
      let subFormattedName = c.name;
      if (c.name.length > 50 && c.name.indexOf(" و ") != -1) {
        let indexOfFirst = c.name.indexOf(" و ");
        subFormattedName =
          c.name.slice(0, indexOfFirst) + "\n" + c.name.slice(indexOfFirst);
      }

      const spanNumber =
        c.subSubjectMarks && c.subSubjectMarks.length > 0
          ? c.subSubjectMarks.length
          : 1;
      const colspan =
        spanNumber == 1
          ? c.subSubjectMarks && c.subSubjectMarks.length > 0
            ? 1
            : 3
          : 1;
      //const lines = subFormattedName.split("\n");
      // Get the maximum length
      //const maxLength = Math.max(...lines.map((line) => line.length));
      const lines = subFormattedName.split("\n");
      //Get the maximum length
      const maxLength = Math.max(...lines.map((line) => line.length));
      let subjectFontSize = maxLength >= 12 ? tableSize * 0.8 : tableSize + 1;

      //ﺍﻟﻤﺎﺩﺓ

      arr[8] = {
        content: subFormattedName,
        rowSpan: spanNumber,
        colSpan: colspan,
        styles: {
          halign: "right",
          valign: "middle",

          fillColor: rgbToHex(...bulletinColors.color7),
          fontSize: subjectFontSize,
          textColor: "#ffffff",
          lineColor: [229, 229, 229],
          cellPadding: {
            top: 0.8,
            bottom: 0.8,
            right: 2,
            left: 1,
          },
        },
      };
      // ﺍﻟﻌﺪﺩ / 20
      arr[5] = {
        content: c.mark ?? "--",
        rowSpan: spanNumber,
        styles: {
          halign: "center",
          valign: "middle",
          fontSize: 10,
          lineColor: [...bulletinColors.color5],
        },
      };

      arr[4] = cellToDelete;
      arr[3] = cellToDelete;
      arr[2] = cellToDelete;

      //best mark
      arr[1] = {
        content: c.max,
        rowSpan: spanNumber,
        styles: {
          halign: "center",
          valign: "middle",
          fontSize: 9,
          cellPadding: 0,
          lineColor: [...bulletinColors.color5],
        },
      };

      //lowest mark
      arr[0] = {
        content: c.min,
        rowSpan: spanNumber,
        styles: {
          halign: "center",
          valign: "middle",
          fontSize: 9,
          cellPadding: 0,
          lineColor: [...bulletinColors.color5],
        },
      };
      //sub subjects
      if (c.subSubjectMarks)
        c.subSubjectMarks.forEach((subs) => {
          try {
            arr[6] = {
              content: subs.mark ?? "--",
              styles: {
                halign: "center",
                valign: "middle",
                cellPadding: 0,
                fontSize: 8,
                fillColor: rgbToHex(...bulletinColors.color9),
                textColor: rgbToHex(...bulletinColors.color2),
                lineColor: bulletinColors.color2,
              },
            };

            arr[7] = {
              content: `${subs.name} (/${subs.maxMark})`,
              styles: {
                halign: "right",
                valign: "middle",
                fontSize: 7,
                fillColor: rgbToHex(...bulletinColors.color9),
                textColor: rgbToHex(...bulletinColors.color2),
                lineColor: bulletinColors.color2,
              },
            };
            array.push(arr);
            arr = [];
          } catch (error) {
            console.error("error sub", error);
          }
        });
      else array.push(arr);
      arr = [];
    });

    //end shifting

    array[0][2] = {
      content: "",
      rowSpan: array.length,
      styles: {
        lineColor: [...bulletinColors.color5],
      },
    };
    //appreciation

    if (
      !m.submodules ||
      m.submodules.every((submodule) => submodule.NbSubjects === 0)
    ) {
      array[0][3] = {
        content: m.appreciation.trim(),
        rowSpan: array.length,
        styles: {
          halign: "center",
          valign: "middle",
          fontSize: m.appreciation.length > 15 ? 10 : 12,
          lineColor: [...bulletinColors.color5],
          cellPadding: 1,
        },
      };
    } else {
      let appIndex = 0;
      m.submodules.forEach((subModule) => {
        const subSubjectCount = m.subjects
          .filter((subject) => subject.submoduleID === subModule._id)
          .reduce(
            (acc, subject) =>
              acc +
              (subject.subSubjectMarks ? subject.subSubjectMarks.length : 1),
            0
          );
        let rowNumber = subSubjectCount;
        array[appIndex][3] = {
          content: subModule.appreciation.trim(),
          rowSpan: rowNumber,
          styles: {
            halign: "center",
            valign: "middle",
            fontSize: 10,
            lineColor: [52, 172, 215],
            cellPadding: 1,
          },
        };
        appIndex += rowNumber;
      });
    }
    //ﻣﻌﺪﻝ ﺍﻟﻤﺠﺎﻝ
    array[0][4] = {
      content: m.moyenne,
      rowSpan: array.length,
      styles: {
        halign: "center",
        fontSize: 12,
        valign: "middle",
        lineColor: [...bulletinColors.color5],
      },
    };

    for (let row = 0; row < array.length; row++) {
      array[row] = array[row].filter((cell) => cell !== cellToDelete);
    }
    doc.autoTable({
      startY: 64 + topMargin,
      theme: "grid",
      styles: {
        font: "Amiri",
        halign: "center",
        fontStyle: "Bold",
      },
      cellPadding: 0,
      body: array,
      margin: { left: 5, right: 5, bottom: 0 },
      didDrawPage: function (data) {
        // Reseting top margin. The change will be reflected only after print the first page.
        data.settings.margin.top = 10;
      },
      columnStyles: {
        0: { cellWidth: 10 },
        1: { cellWidth: 10 },
        2: { cellWidth: 1 },
        3: { cellWidth: 38 },
        4: { cellWidth: 15 },
        5: { cellWidth: 16 },
        6: { cellWidth: 8 },
        7: { cellWidth: 40 },
        8: { cellWidth: 23 },
        9: { cellWidth: 40 },
      },
    });

    topMargin = doc.lastAutoTable.finalY - 73 + 2;
  });

  // Generate the table
  let header = [
    "إمضاء الولي",
    "الشهادة",
    "أدنى معدل",
    "أعلى معدل",
    "معدل الثلاثي",
  ];
  let formatedHeader = header.map((name, index) => {
    let fillColor = rgbToHex(...bulletinColors.color9);
    let textColor = rgbToHex(...bulletinColors.color2);
    let lineColor = bulletinColors.color2;
    if (index == 0 || index == 4) {
      fillColor = rgbToHex(...bulletinColors.color7);
      textColor = "#ffffff";
      lineColor = [229, 229, 229];
    }

    return {
      content: name,
      styles: {
        halign: "center",
        valign: "middle",
        fillColor: fillColor,
        textColor: textColor,
        lineColor: lineColor,
      },
    };
  });
  let arr = [];

  arr[0] = "";
  arr[1] = affichageBulletin.certif
    ? getCertificationNameByMoyenne(student.moyenne, certifs)
    : "";
  arr[2] = bulletinData.minMoyenne;
  arr[3] = bulletinData.maxMoyenne;
  arr[4] = student.moyenne;
  let array = [];
  array.push(formatedHeader);
  array.push(arr);
  let startYFinalTab = doc.previousAutoTable.finalY + 5;
  doc.autoTable({
    startY: startYFinalTab, // Add margin
    body: array,
    theme: "grid",
    styles: {
      font: "Amiri",
      halign: "center",
      fontStyle: "Bold",
    },
    cellPadding: 0,
    margin: { left: 40, right: 5, bottom: 0, top: 5 },
    columnStyles: {
      0: { cellWidth: 47 },
      1: { cellWidth: 33 },
      2: { cellWidth: 28 },
      3: { cellWidth: 28 },
      4: { cellWidth: 28 },
    },
  });
  //stamp
  doc.setFontSize(11);
  doc.setTextColor(0, 0, 0);
  text = "امضاء مدير)ة( المدرسة";

  doc.text(text, 5, startYFinalTab + 5);

  if (config.showStamp && stampImage && stampImage.src) {
    try {
      doc.addImage(stampImage, "PNG", 5, startYFinalTab - 10, 35, 35);
    } catch (e) {}
  }
  //end stamp
};
const prePrintBulletin = async (template, config, this_) => {
  const { selectedStudent, selectedTrimester, bulletinType } = config;
  let bulletinData = {};
  // Fetching bulletin data
  try {
    const { data } = await apiClient.get(
      `/bulletin/v2/bt/${bulletinType}/${selectedStudent}/${selectedTrimester}`
    );
    bulletinData = data;
  } catch (error) {
    console.error("Failed to fetch bulletin data:", error);
    this_.printLoading = false;
    return;
  }

  // Preprint checks
  let missingMarks = [];
  bulletinData.modules.forEach((m) => {
    m.subjects.forEach((c) => {
      if (c.mark === "--" || c.mark == null) missingMarks.push(c.name);
    });
  });

  // If there are missing marks, show a confirmation modal
  if (missingMarks.length !== 0) {
    const confirmed = await new Promise((resolve) => {
      Modal.confirm({
        title: this_.$t("bulletin.confirmPrintMatieres", {
          count: missingMarks.length,
        }),
        content: (h) => {
          const list = missingMarks.map((s) => h("li", s));
          return h("ul", list);
        },
        okText: this_.$t("bulletin.generateAnyway"),
        okType: "danger",
        cancelText: this_.$t("action.annuler"),
        async onOk() {
          try {
            await printBulletin(template, config, false, bulletinData);
            resolve(true); // User confirmed
          } catch (error) {
            console.error("Failed to print bulletin:", error);
            resolve(false);
          }
        },
        onCancel() {
          this_.printLoading = false;
          resolve(false); // User cancelled
        },
      });
    });

    if (!confirmed) {
      return; // Exit if not confirmed
    }
  } else {
    // Directly print if there are no missing marks
    try {
      await printBulletin(template, config, false, bulletinData);
    } catch (error) {
      console.error("Failed to print bulletin:", error);
    }
  }
};
function getLogoWidth(imgData, fixedHeight = 14) {
  const originalWidth = imgData.width;
  const originalHeight = imgData.height;
  const aspectRatio = originalWidth / originalHeight;
  const autoWidth = fixedHeight * aspectRatio;
  return isNaN(autoWidth) || !isFinite(autoWidth) ? fixedHeight : autoWidth;
}

function loadImage(src) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = src;
    img.onload = () => resolve(img); // Resolve the promise when the image loads
    img.onerror = (err) => reject(err); // Reject if there's an error
  });
}

const prePrintAllBulletin = async (template, config, this_, missingStudent) => {
  if (missingStudent && missingStudent.length !== 0) {
    const confirmed = await new Promise((resolve) => {
      Modal.confirm({
        title: this_.$t("bulletin.confirmPrintEleves", {
          count: missingStudent.length,
        }),
        content: (h) => {
          const list = missingStudent.map((s) => h("li", s));
          return h("ul", list);
        },
        okText: this_.$t("bulletin.generateAnyway"),
        okType: "danger",
        cancelText: this_.$t("action.annuler"),
        async onOk() {
          try {
            await printAllBulletin(template, config);
            resolve(true); // User confirmed
          } catch (error) {
            console.error("Error in printAllBulletin:", error);
            resolve(false);
          }
        },
        onCancel() {
          this_.printLoading = false;
          resolve(false); // User cancelled
        },
      });
    });

    if (!confirmed) {
      return; // Exit if user cancelled
    }
  } else {
    // Directly print if there are no missing students
    try {
      await printAllBulletin(template, config);
    } catch (error) {
      console.error("Error in printAllBulletin:", error);
    }
  }
};
const printBulletin = async function (
  useTemplateSchool,
  config,
  preview = false,
  bulletinData
) {
  const {
    selectedLevel,
    schoolDetails,
    selectedStudent,
    selectedStudentName,
    settings,
    type,
    studentsData,
    bulletinType,
  } = config;
  studentCount = 1;
  const doc = new JsPDF();
  const ctx = doc.context2d;
  let affichageBulletin;

  await apiClient
    .post("/affichageBulletin/filter", {
      query: {
        level: selectedLevel,
        type: type,
      },
    })
    .then((res) => {
      if (res.data[0]) {
        affichageBulletin = res.data[0];
      } else {
        affichageBulletin = {
          verySpecific: true,
          pedagogic: false,
          personalized: false,
          logo: true,
          verySpecificName: "معدل",
        };
      }
    })
    .catch((e) => {
      $message.error($t("error.erreur"));
    });
  if (useTemplateSchool != "MS" && affichageBulletin.logo) {
    let arrayOfImages = settings.image;
    for (var i = 0; i < arrayOfImages.length; i++) {
      if (settings.activeBuilding === arrayOfImages[i].db) {
        var imgData = await loadImage(arrayOfImages[i].logo);
      }
    }
    const pdf_width = doc.internal.pageSize.getWidth();
    let logoAutoWidth = getLogoWidth(imgData, 14);

    if (useTemplateSchool == "PP") {
      drawBulletinpythagore(doc, bulletinData, affichageBulletin, config);
      if (preview) return doc;
      else
        return doc.save(`Bulletin ${bulletinType} ${selectedStudentName}.pdf`);
    } else if (useTemplateSchool == "MP")
      doc.addImage(imgData, "JPEG", pdf_width / 2 - 10, 0, 20, 20);
    else if (useTemplateSchool == "CP") {
      logoAutoWidth = getLogoWidth(imgData, 17);
      doc.addImage(imgData, "JPEG", 24, 2, logoAutoWidth, 17);
      //doc.addImage(imgData, "JPEG", 10, 3, logoAutoWidth, 17);
      const student = studentsData.find((s) => s._id == selectedStudent);
      if (affichageBulletin.photo && student && student.photo) {
        let studentPhotoURL = settings.base_url + "/" + student.photo;
        let studentIMG = new Image();
        studentIMG.src = studentPhotoURL;
        try {
          doc.addImage(studentIMG, "JPEG", pdf_width / 2 - 7, 2, 16, 18);
        } catch (error) {}
      }
    } else if (useTemplateSchool == "EV") {
      let govIMG = new Image();
      govIMG.src = "/resources/images/govImg.png";
      try {
        doc.addImage(govIMG, "JPEG", pdf_width / 2 + 28 / 2, 6, 14, 14);
      } catch (error) {}

      doc.addImage(imgData, "JPEG", pdf_width / 2 - 46 / 2, 6, 14, 14);
    } else {
      const student = studentsData.find((s) => s._id == selectedStudent);
      if (affichageBulletin.photo && student && student.photo) {
        let studentPhotoURL = settings.base_url + "/" + student.photo;
        let studentIMG = new Image();
        studentIMG.src = studentPhotoURL;
        try {
          doc.addImage(studentIMG, "JPEG", pdf_width / 2 - 7, 2, 16, 18);
        } catch (error) {}
        doc.addImage(imgData, "JPEG", 28, 2, logoAutoWidth, 14);
      } else
        doc.addImage(
          imgData,
          "JPEG",
          pdf_width / 2 - logoAutoWidth / 2,
          6,
          logoAutoWidth,
          14
        );
    }
  }
  //stamp
  let stamp = settings.base_url + "/" + schoolDetails.stamp;
  const stampImage = new Image(30, 30);
  if (stamp) stampImage.src = stamp;
  if (stamp) {
    try {
      await waitLoadingImage(stampImage);
    } catch (e) {}
  }
  //end stamp
  if (
    affichageBulletin.fuseSubSubject &&
    affichageBulletin.fuseSubSubject === true
  )
    bulletinData = fuseSubSubjects(bulletinData);

  if (useTemplateSchool == "MP") {
    console.log("template mondial");
    drawBulletinMondial(
      doc,
      ctx,
      bulletinData,
      bulletinData,
      affichageBulletin,
      stampImage,
      config
    );
  } else if (useTemplateSchool == "MS") {
    drawBulletinMalekEsaghir(
      doc,
      ctx,
      bulletinData,
      bulletinData,
      affichageBulletin,
      stampImage,
      config
    );
  } else if (useTemplateSchool == "AC") {
    //QR CODE
    const QR_CODE = "/resources/images/templatesBulletin/ArcEnciel_QR_Code.png";
    const qrImage = new Image(40, 40);
    if (QR_CODE) {
      qrImage.src = QR_CODE;
      try {
        await waitLoadingImage(qrImage);
      } catch (e) {}
    }
    //end QR CODE
    drawBulletinArcEnciel(
      doc,
      ctx,
      bulletinData,
      bulletinData,
      affichageBulletin,
      stampImage,
      qrImage,
      config
    );
  } else if (useTemplateSchool == "CP") {
    console.info("Printing coupole template");
    drawBulletinCoupole(
      doc,
      ctx,
      bulletinData,
      bulletinData,
      affichageBulletin,
      stampImage,
      config
    );
  } else if (useTemplateSchool == "EV") {
    drawBulletinEverest(
      doc,
      ctx,
      bulletinData,
      bulletinData,
      affichageBulletin,
      stampImage,
      config
    );
  } else
    drawBulletin(
      doc,
      ctx,
      bulletinData,
      bulletinData,
      affichageBulletin,
      stampImage,
      config,
      useTemplateSchool
    );
  if (preview) return doc;
  else doc.save(`Bulletin ${bulletinType} ${selectedStudentName}.pdf`);
};
const printAllBulletin = async function (useTemplateSchool, config) {
  const {
    selectedLevel,
    schoolDetails,
    type,
    selectedTrimester,
    settings,
    selectedClasse,
    selectedClassName,
    bulletinType,
    studentsData,
  } = config;
  studentCount = 1;
  const doc = new JsPDF();
  const ctx = doc.context2d;
  let bulletinData;
  let firstPage = true;
  let affichageBulletin;
  await apiClient
    .post("/affichageBulletin/filter", {
      query: {
        level: selectedLevel,
        type: type,
      },
    })
    .then((res) => {
      if (res.data[0]) {
        affichageBulletin = res.data[0];
      } else {
        affichageBulletin =
          type == "peda"
            ? {
                numberStudentInsc: false,
                identUnique: false,
                type: "peda",
              }
            : {
                verySpecific: false,
                pedagogic: true,
                personalized: false,
                logo: true,
                verySpecificName: "معدل",
              };
      }
    })
    .catch((e) => {
      $message.error($t("error.erreur"));
    });
  // getAllStudentBulletinVerySpecific

  await apiClient
    .get(
      "/bulletin/v2/bt/" +
        bulletinType +
        "/all/" +
        selectedClasse +
        "/" +
        selectedTrimester
    )
    .then(({ data }) => {
      bulletinData = data;
    });

  //stamp
  let stamp = settings.base_url + "/" + schoolDetails.stamp;
  const stampImage = new Image(30, 30);
  if (stamp) stampImage.src = stamp;
  if (stamp) {
    try {
      await waitLoadingImage(stampImage);
    } catch (e) {
      console.info(e);
    }
  }
  //end stamp
  //QR CODE
  const QR_CODE = "/resources/images/templatesBulletin/ArcEnciel_QR_Code.png"; // stg + prod
  const qrImage = new Image(40, 40);
  if (QR_CODE) {
    qrImage.src = QR_CODE;
    try {
      await waitLoadingImage(qrImage);
    } catch (e) {}
  }
  //end QR CODE
  if (
    (!useTemplateSchool ||
      ["MP", "AC", "CP", "EV"].includes(useTemplateSchool)) &&
    affichageBulletin.logo
  ) {
    let arrayOfImages = settings.image;
    for (var i = 0; i < arrayOfImages.length; i++) {
      if (settings.activeBuilding === arrayOfImages[i].db) {
        var imgData = await loadImage(arrayOfImages[i].logo);
      }
    }
  }
  const pdf_width = doc.internal.pageSize.getWidth();
  bulletinData.students.forEach((student) => {
    if (!firstPage) {
      doc.addPage();
      studentCount++;
    }
    if (useTemplateSchool == "CP") {
      let logoAutoWidth = getLogoWidth(imgData, 17);
      doc.addImage(imgData, "JPEG", 24, 2, logoAutoWidth, 17);
      //doc.addImage(imgData, "JPEG", 10, 3, logoAutoWidth, 17);
      const studentInfo = studentsData.find((s) => s._id == student.id);
      if (affichageBulletin.photo && studentInfo && studentInfo.photo) {
        let studentPhotoURL = settings.base_url + "/" + studentInfo.photo;
        let studentIMG = new Image();
        studentIMG.src = studentPhotoURL;
        try {
          doc.addImage(studentIMG, "JPEG", pdf_width / 2 - 7, 2, 16, 18);
        } catch (error) {}
      }
    } else if (
      (!useTemplateSchool || ["MP", "AC"].includes(useTemplateSchool)) &&
      affichageBulletin.logo
    ) {
      const studentInfo = studentsData.find((s) => s._id == student.id);
      let logoAutoWidth = getLogoWidth(imgData, 14);
      if (affichageBulletin.photo && studentInfo && studentInfo.photo) {
        let studentPhotoURL = settings.base_url + "/" + studentInfo.photo;
        let studentIMG = new Image();
        studentIMG.src = studentPhotoURL;
        doc.addImage(studentIMG, "JPEG", pdf_width / 2 - 7, 2, 16, 18);
        doc.addImage(imgData, "JPEG", 28, 2, logoAutoWidth, 14);
      } else
        doc.addImage(
          imgData,
          "JPEG",
          pdf_width / 2 - logoAutoWidth / 2,
          6,
          logoAutoWidth,
          14
        );
    }

    if (
      affichageBulletin.fuseSubSubject &&
      affichageBulletin.fuseSubSubject === true
    )
      student = fuseSubSubjects(student);
    if (useTemplateSchool == "MP") {
      doc.addImage(imgData, "JPEG", pdf_width / 2 - 10, 0, 20, 20);
      drawBulletinMondial(
        doc,
        ctx,
        student,
        bulletinData,
        affichageBulletin,
        stampImage,
        config
      );
    } else if (useTemplateSchool == "MS")
      drawBulletinMalekEsaghir(
        doc,
        ctx,
        student,
        bulletinData,
        affichageBulletin,
        stampImage,
        config
      );
    else if (useTemplateSchool == "AC") {
      drawBulletinArcEnciel(
        doc,
        ctx,
        student,
        bulletinData,
        affichageBulletin,
        stampImage,
        qrImage,
        config
      );
    } else if (useTemplateSchool == "CP") {
      console.info("Printing coupole template");
      drawBulletinCoupole(
        doc,
        ctx,
        student,
        bulletinData,
        affichageBulletin,
        stampImage,
        config
      );
    } else if (useTemplateSchool == "PP") {
      drawBulletinpythagore(doc, student, affichageBulletin, config);
    } else if (useTemplateSchool == "EV") {
      let govIMG = new Image();
      govIMG.src = "/resources/images/govImg.png";
      try {
        doc.addImage(govIMG, "JPEG", pdf_width / 2 + 28 / 2, 6, 14, 14);
      } catch (error) {}

      doc.addImage(imgData, "JPEG", pdf_width / 2 - 46 / 2, 6, 14, 14);
      drawBulletinEverest(
        doc,
        ctx,
        student,
        bulletinData,
        affichageBulletin,
        stampImage,
        config
      );
    } else
      drawBulletin(
        doc,
        ctx,
        student,
        bulletinData,
        affichageBulletin,
        stampImage,
        config
      );
    firstPage = false;
  });

  const className = selectedClassName;
  doc.save(`Bulletin ${className}.pdf`);
};

export default {
  drawBulletin,
  drawBulletinMalekEsaghir,
  drawBulletinMondial,
  printBulletin,
  printAllBulletin,
  prePrintBulletin,
  prePrintAllBulletin,
};
